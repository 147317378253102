import { Box, Link, Stack } from '@mui/material';
import { images } from '../../assets/images';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
import { nextQuestion } from '../../redux/onboarding';
import { useGetUserProfileQuery } from '../../api/api';
import { useTranslation } from 'react-i18next';

export default function CompletedViewMobile() {
  const { t } = useTranslation();
  const questions = useAppSelector((state) => state.onboarding.questions);

  const dispatch = useAppDispatch();

  const { data } = useGetUserProfileQuery();

  const isFinished = useMemo(() => {
    if (questions.length === 0) {
      return false;
    }

    return questions[questions.length - 1].finished;
  }, [questions]);

  return (
    <Stack flex={1} justifyContent={'center'} alignItems={'center'}>
      <Box
        component={'img'}
        src={images.v2.onboarding.finishedLogo}
        sx={{ height: 0, flexGrow: 1, objectFit: 'contain' }}
      />

      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'8px'}
        className="mb-[20px]"
      >
        {!isFinished && (
          <LoadingButton
            sx={{
              color: 'white',
              border: '1px solid white',
              padding: '5px 16px',

              '&.MuiLoadingButton-root': {
                background: 'transparent',
              },
            }}
            className={`button my-10`}
            variant="contained"
            onClick={() => dispatch(nextQuestion())}
          >
            {t('continueHere')}
          </LoadingButton>
        )}

        <Link
          className={`button mobile my-[0px] bg-white rounded-[24px] py-[12px] px-[24px] text-text text-center no-underline`}
          sx={{ fontFamily: 'GeneralSans', fontSize: '14px' }}
          href={`https://yzk5e.app.link/Sozh5Tqwcub?email=${data?.email}&signIn=true`}
        >
          {t('downloadTheApp')}
        </Link>
      </Stack>
    </Stack>
  );
}
