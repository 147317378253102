import { useEffect, useMemo, useRef } from 'react';
import { images } from '../../assets/images';
import { useAppSelector } from '../../redux/store';
import { Box, Fade, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import HeightForm from '../../onboarding/components/onboarding/HeightForm';
import WeightForm from '../../onboarding/components/onboarding/WeightForm';
import { OnboardingContinueRef } from '../../redux/onboardingTypes';
import QuestionForm from '../../onboarding/components/onboarding/QuestionForm';
import QuestionSliderForm from '../../onboarding/components/onboarding/QuestionSliderForm';
import LQIViewMobile from './LQIViewMobile';
import GridFormMobile from './GridFormMobile';
import CompletedViewMobile from './CompletedViewMobile';
import LQIVideoViewMobile from './LQIVideoViewMobile';
import { BeatLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';

export default function OnboardingQuestionsView(props: Props) {
  const { t } = useTranslation();
  const { onHeightMeasured } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const ref = useRef<OnboardingContinueRef>(null);

  const loading = useAppSelector((state) => state.onboarding.loadingQuestion);
  const buttonEnabled = useAppSelector(
    (state) => state.onboarding.buttonEnabled
  );

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        onHeightMeasured(containerRef.current!.getBoundingClientRect().height);
      });
      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [onHeightMeasured]);

  const questions = useAppSelector((state) => state.onboarding.questions);

  const lastQuestion = useMemo(() => {
    if (questions.length === 0) {
      return undefined;
    }

    return questions[questions.length - 1];
  }, [questions]);

  const view = useMemo(() => {
    if (loading || !lastQuestion) {
      return (
        <Stack flex={1} justifyContent={'center'} alignItems={'center'}>
          <BeatLoader color={'white'} />
        </Stack>
      );
    }

    if (lastQuestion.finished) {
      return <CompletedViewMobile />;
    }

    if (lastQuestion.is_lqi_calc) {
      return <LQIVideoViewMobile ref={ref} />;
    } else if (lastQuestion.is_height) {
      return <HeightForm question={lastQuestion} ref={ref} />;
    } else if (lastQuestion.is_weight) {
      return <WeightForm question={lastQuestion} ref={ref} />;
    } else if (lastQuestion.is_goal) {
      return <QuestionForm question={lastQuestion} ref={ref} />;
    } else if (lastQuestion.is_interests) {
      return (
        <GridFormMobile
          ref={ref}
          question={lastQuestion}
          onCustomAnswerEntered={() => Promise.resolve(true)}
        />
      );
    } else if (lastQuestion.is_diets) {
      return (
        <GridFormMobile
          ref={ref}
          question={lastQuestion}
          onCustomAnswerEntered={() => Promise.resolve(true)}
        />
      );
    } else if (lastQuestion.is_allegens) {
      return (
        <GridFormMobile
          ref={ref}
          question={lastQuestion}
          onCustomAnswerEntered={() => Promise.resolve(true)}
        />
      );
    } else if (lastQuestion.is_lqi_dce) {
      return <LQIViewMobile question={lastQuestion} />;
    } else if (lastQuestion.is_categorical) {
      return <QuestionForm question={lastQuestion} ref={ref} />;
    } else {
      return <QuestionSliderForm question={lastQuestion} ref={ref} />;
    }
  }, [lastQuestion, loading]);

  return (
    <Stack
      ref={containerRef}
      className={`max-h-[400px] overflow-scroll self-stretch min-h-[360px] rounded-[36px] rounded-br-[0px] rounded-bl-[0px] pt-[20px] justify-start absolute top-0 left-0 right-0 ${
        lastQuestion && lastQuestion.finished ? 'bottom-0' : ''
      }`}
    >
      <Fade in={true}>
        <Stack className={'flex-1 z-10 relative'}>{view}</Stack>
      </Fade>

      {!loading &&
        lastQuestion &&
        !lastQuestion.finished &&
        !lastQuestion.is_lqi_dce && (
          <LoadingButton
            variant="contained"
            endIcon={<Box component="img" src={images.v2.button.tickBlack} />}
            className={`button mobile mb-[40px] mt-[10px]`}
            sx={{
              '&.MuiLoadingButton-root': { background: 'white' },
              '&.Mui-disabled': { background: '#FFFFFF99' },
            }}
            disabled={!buttonEnabled}
            onClick={() => {
              if (ref.current) {
                ref.current.onContinuePressed();
              }
            }}
          >
            {t('confirm')}
          </LoadingButton>
        )}
    </Stack>
  );
}

interface Props {
  onHeightMeasured: (height: number) => void;
}
