import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  OnboardingQuestion,
  OnboardingV2Step,
  SYDMesssage,
} from './onboardingTypes';
import { mlAPIheaders } from './onboardingActions';
import { mlClient } from '../common/environment';
import { wait, TIMEOUT } from '../common/v2/hooks';
import { RootState } from './store';
import { checkoutActions } from './checkout';

interface State {
  questions: OnboardingQuestion[];
  buttonEnabled: boolean;
  loadingQuestion: boolean;
  currentQuestionIndex: number;

  sydMessages: SYDMesssage[];
  onboardingStep: OnboardingV2Step;

  showRightQuestion: boolean;
  showingMessages: boolean;
  hasShowedCitizenshipMessages: boolean;

  qrPosition?: { x: number; y: number };
}

const initialState: State = {
  questions: [],
  buttonEnabled: false,
  loadingQuestion: false,
  currentQuestionIndex: 0,

  sydMessages: [],
  onboardingStep: OnboardingV2Step.ANIMATING,
  showRightQuestion: false,
  showingMessages: false,

  hasShowedCitizenshipMessages: false,
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setButtonEnabled(state, action: PayloadAction<boolean>) {
      state.buttonEnabled = action.payload;
    },
    clearQuestions(state) {
      state.questions = [];
    },
    addMessage(state, action: PayloadAction<Omit<SYDMesssage, 'index'>>) {
      state.sydMessages.push({
        ...action.payload,
        index: state.sydMessages.length,
      });
    },
    clearMessages(state) {
      state.sydMessages = [];
    },
    setOnboardingStep(state, action: PayloadAction<OnboardingV2Step>) {
      state.onboardingStep = action.payload;
    },

    setShowingMessages(state, action: PayloadAction<boolean>) {
      state.showingMessages = action.payload;
    },
    setQRPosition(state, action: PayloadAction<{ x: number; y: number }>) {
      state.qrPosition = action.payload;
    },
  },

  extraReducers(builder) {
    builder.addCase(nextQuestion.fulfilled, (state, action) => {
      state.questions = action.payload.questions;
      if (state.currentQuestionIndex === undefined) {
        state.currentQuestionIndex = action.payload.questions.findIndex(
          (item) => item.answer === ''
        );
      } else {
        state.currentQuestionIndex++;
      }
      state.loadingQuestion = false;
    });

    builder.addCase(checkoutActions.setNavigationState, (state, action) => {
      if (
        action.payload === 'LOGGED_IN' ||
        action.payload === 'SHOW_SUCCESS_ONBOARDING'
      ) {
        state.onboardingStep = OnboardingV2Step.LOGGED_IN;
      } else {
        state.onboardingStep = OnboardingV2Step.ANIMATING;
      }
    });

    builder.addMatcher(
      isAnyOf(nextQuestion.pending, answerQuestion.pending),
      (state) => {
        state.loadingQuestion = true;
      }
    );

    builder.addMatcher(
      isAnyOf(nextQuestion.pending, answerQuestion.pending),
      (state) => {
        state.showRightQuestion = false;
      }
    );

    builder.addMatcher(
      isAnyOf(nextQuestion.fulfilled, answerQuestion.fulfilled),
      (state) => {
        state.showRightQuestion = true;
      }
    );
  },
});

export const onboardingActions = onboardingSlice.actions;

export default onboardingSlice.reducer;

export const answerQuestion = createAsyncThunk(
  'onboarding/answer',
  async (
    payload: {
      question_id: string;
      answers: string[];
      imperial?: boolean;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;

    const question =
      state.onboarding.questions[state.onboarding.questions.length - 1];

    if (question.is_height || question.is_weight) {
      dispatch(
        onboardingActions.addMessage({
          text: payload.answers[0],
          from: 'user',
          type: 'normal',
        })
      );
      await wait(TIMEOUT);
    } else if (
      question.is_interests ||
      question.is_diets ||
      question.is_allegens
    ) {
      dispatch(
        onboardingActions.addMessage({
          text: payload.answers.join(', '),
          from: 'user',
          type: 'normal',
        })
      );
      await wait(TIMEOUT);
    } else {
      for (const answer of payload.answers) {
        dispatch(
          onboardingActions.addMessage({
            text: question.answers.find((item) => item.id === answer)!.text,
            from: 'user',
            type: 'normal',
          })
        );
        await wait(TIMEOUT);
      }
    }

    const { imperial, answers, question_id } = payload;
    const mappedAnswers = answers.map((item) => ({
      answer_id: item,
      question_id,
    }));

    await mlClient.post<{ finished: boolean }>(
      'a',
      { answers: mappedAnswers },
      {
        headers: await mlAPIheaders(imperial !== undefined ? { imperial } : {}),
      }
    );

    return dispatch(nextQuestion());
  }
);
export const nextQuestion = createAsyncThunk(
  'onboarding/iq',
  async (_, { dispatch }) => {
    const { data } = await mlClient.get<{ questions: OnboardingQuestion[] }>(
      'iqV3',
      {
        headers: { ...(await mlAPIheaders()), version: 6 },
      }
    );

    const { questions } = data;
    const currentQuestion = questions[questions.length - 1];
    if (!currentQuestion.finished) {
      for (let i = 0; i < currentQuestion.messages.length; i++) {
        dispatch(
          onboardingActions.addMessage({
            text: currentQuestion.messages[i],
            from: 'syd',
            type: 'normal',
          })
        );

        await wait(TIMEOUT);
      }
    }

    return data;
  }
);
