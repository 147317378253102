import { Fade, Stack, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { theme } from '../../common/theme';
import LargeInput from '../../onboarding/components/LargeInput';
import { largeInputStyles } from '../../onboarding/components/styles';
import moment from 'moment';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { appEnvironment, autoCompleteData } from '../../common/environment';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useSYDMessages } from '../../common/v2/hooks';
import { ContinueRef } from '../../onboarding/types';
import analyticsTracker from '../../common/analytics';
import {
  analyticsEvents,
  analyticsScreenNames,
} from '../../common/analyticsEvents';
import { checkoutActions } from '../../redux/checkout';
import { onboardingActions } from '../../redux/onboarding';
import { OnboardingV2Step } from '../../redux/onboardingTypes';
import { useMobile } from '../../common/hooks';
import LoadingMessagesContainer from './LoadingMessagesContainer';
import { useTranslation } from 'react-i18next';
import i18next from '../../i18n';

const MIN_BIRTH_DATE = dayjs(moment().subtract(13, 'years').toDate());

const DateOfBirth = forwardRef<ContinueRef, unknown>((_props, ref) => {
  useImperativeHandle(ref, () => ({
    continuePressed: async () => {
      analyticsTracker.track(analyticsEvents.CLICK_CONFIRM_BUTTON, {
        screen: analyticsScreenNames.BIRTH_DATE,
      });

      dispatch(
        checkoutActions.setPersonalData({
          birthDate: birthDate!.format('DD-MM-YYYY'),
        })
      );
      dispatch(onboardingActions.setOnboardingStep(OnboardingV2Step.COUNTRY));
    },
  }));

  const dispatch = useAppDispatch();
  const name = useAppSelector((state) => state.checkout.personalData.name);

  const { showMessages } = useSYDMessages();

  const [fadeIn, setFadeIn] = useState(false);

  const hasSentMessages = useRef(false);

  const mobile = useMobile();
  const { t } = useTranslation();

  useEffect(() => {
    if (!hasSentMessages.current) {
      hasSentMessages.current = true;

      if (mobile) {
        dispatch(onboardingActions.clearMessages());
        showMessages(
          [t('welcome', { name }), t('whatsYourBirthdate')],
          undefined,
          true
        ).then(() => setFadeIn(true));
      } else {
        showMessages(
          [t('allDone'), t('welcome', { name }), t('whatsYourBirthdate')],
          0,
          true
        ).then(() => setFadeIn(true));
      }
    }
  }, [dispatch, mobile, name, showMessages, t]);

  const [birthDate, setBirthDate] = useState<Dayjs | null>(
    appEnvironment.autocomplete ? autoCompleteData.birthDate : null
  );

  const onChange = useCallback((value: Dayjs | null) => {
    if (value) {
      setBirthDate(value);
    }
  }, []);

  useEffect(() => {
    dispatch(
      onboardingActions.setButtonEnabled(
        birthDate !== null && birthDate.isValid()
      )
    );
  }, [birthDate, dispatch]);

  const language = i18next.language;

  return (
    <LoadingMessagesContainer>
      <Fade in={fadeIn}>
        <Stack
          className={`${mobile ? 'flex-1' : 'mx-40'} w-full h-full`}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={language}
            dateFormats={{
              monthAndYear: language === 'ja' ? 'M月 YYYY' : 'MM/YYYY',
            }}
          >
            <DatePicker
              inputFormat={
                language === 'ja' ? 'YYYY年/MM月/DD日' : 'DD/MM/YYYY'
              }
              value={birthDate}
              maxDate={MIN_BIRTH_DATE}
              onChange={onChange}
              InputProps={{
                type: 'text',
                placeholder: language === 'ja' ? 'YYYY/MM/DD' : 'DD/MM/YYYY',
              }}
              defaultCalendarMonth={MIN_BIRTH_DATE}
              renderInput={({ InputProps, inputRef, inputProps }) => (
                <LargeInput
                  ref={inputRef}
                  inputProps={inputProps}
                  white={mobile}
                  sx={{
                    ...largeInputStyles.countryInput,
                    ...(mobile ? largeInputStyles.countryInputWhiteBorder : {}),

                    '& .MuiInputAdornment-root': {
                      marginRight: theme.spacing(3),
                      marginTop: theme.spacing(2),
                    },
                  }}
                  {...InputProps}
                />
              )}
            />
          </LocalizationProvider>

          <Typography
            className={`font-semibold mt-[18px] ${
              mobile ? 'text-[white] text-[16px]' : 'text-[24px]'
            }`}
          >
            {t('birthdate')}
          </Typography>
        </Stack>
      </Fade>
    </LoadingMessagesContainer>
  );
});

export default DateOfBirth;
