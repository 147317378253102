import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ContinueRef } from '../../onboarding/types';
import { Fade, Box, Stack, Typography } from '@mui/material';
import { images } from '../../assets/images';
import { createStylesUtils } from '../../common/common';
import { formStyles } from '../../onboarding/styles';
import analyticsTracker from '../../common/analytics';
import {
  analyticsEvents,
  analyticsScreenNames,
} from '../../common/analyticsEvents';
import { useMobile } from '../../common/hooks';
import { useShoppingCart } from '../../onboarding/common/hooks';
import { checkoutActions } from '../../redux/checkout';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useSYDMessages } from '../../common/v2/hooks';
import { onboardingActions } from '../../redux/onboarding';
import { OnboardingV2Step } from '../../redux/onboardingTypes';
import LoadingMessagesContainer from './LoadingMessagesContainer';
import { useTranslation } from 'react-i18next';

const SummaryV2Form = forwardRef<ContinueRef, unknown>((_props, ref) => {
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    continuePressed: async () => {
      analyticsTracker.track(
        analyticsEvents.CLICK_PAY_AND_ACTIVATE_NOW_BUTTON,
        { screen: analyticsScreenNames.SUMMARY }
      );

      await dispatch(
        onboardingActions.setOnboardingStep(OnboardingV2Step.PAYMENT_INFO)
      );
    },
  }));

  const dispatch = useAppDispatch();

  useEffect(() => {
    analyticsTracker.track(analyticsEvents.VIEW_SUMMARY_SCREEN);
    dispatch(onboardingActions.setButtonEnabled(true));
  }, [dispatch]);

  const { isSubscription, price } = useShoppingCart();

  const gender = useAppSelector((state) => state.checkout.personalData.gender);
  const cart = useAppSelector((state) => state.checkout.cartV2);

  useEffect(() => {
    dispatch(checkoutActions.setButtonState(true));
  }, [dispatch]);

  const mobile = useMobile();

  const paymentTypeText = useMemo(() => {
    if (price === 0) {
      return t('noPaymentRequired');
    } else {
      return isSubscription ? t('subscription') : t('oneOffPayment');
    }
  }, [isSubscription, price, t]);

  const priceText = useMemo(() => {
    if (price === 0) {
      return t('FREE');
    } else {
      return t('totalPrice', {
        price,
        period: isSubscription ? t('perMonth') : '',
        interpolation: { escapeValue: false },
      });
    }
  }, [isSubscription, price, t]);

  const reduxToGender = useMemo(() => {
    switch (gender) {
      case 'F':
        return t('female');
      case 'NB':
        return t('nonBinary');
      case 'M':
      default:
        return t('male');
    }
  }, [gender, t]);

  const genderImage = useMemo(() => {
    switch (gender) {
      case 'M':
        return images.genders.male;
      case 'NB':
        return images.genders.nonBinary;
      case 'F':
      default:
        return images.genders.female;
    }
  }, [gender]);

  const { showMessages } = useSYDMessages();
  const hasShownMessagesRef = useRef(false);

  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    if (!hasShownMessagesRef.current) {
      hasShownMessagesRef.current = true;
      const messages = [reduxToGender, t('iKnewThis'), t('almostThere')];

      if (
        cart &&
        cart.items.length > 0 &&
        cart.items[0].product.code === 'complete'
      ) {
        showMessages([t('almostThere')]).then(() => setFadeIn(true));
      } else {
        showMessages(messages, 0, true).then(() => setFadeIn(true));
      }
    }
  }, [cart, gender, reduxToGender, showMessages, t]);

  const rtl = useMemo(() => {
    return document.getElementById('root')?.getAttribute('dir') === 'rtl';
  }, []);

  return (
    <LoadingMessagesContainer>
      <Fade in={fadeIn}>
        <Box
          sx={[
            formStyles.container,
            mobile
              ? { flex: 1, justifyContent: 'center', alignItems: 'center' }
              : {},
          ]}
        >
          <Stack
            sx={[styles.container, mobile ? styles.containerMobile : {}]}
            direction={mobile ? 'row-reverse' : 'row'}
          >
            <Box
              component="img"
              src={images.summary.summaryBackground}
              className={`absolute left-0 right-0 top-0 bottom-0 z-[-1] ${
                rtl ? 'rotate-180' : ''
              }`}
            />

            <Stack
              sx={{
                width: mobile ? undefined : '330px',
                padding: `34px ${mobile ? '20px' : '40px'}`,
              }}
              alignItems="flex-start"
            >
              <Typography sx={styles.membership}>{t('MEMBERSHIP')}</Typography>
              <Typography sx={styles.oneOffPayment}>
                {paymentTypeText}
              </Typography>
              <Typography sx={[styles.sum, mobile ? styles.sumMobile : {}]}>
                {priceText}
              </Typography>
              <Box
                component="img"
                src={images.summary.summaryIcons}
                sx={{ height: '32px' }}
              />
            </Stack>

            <Stack
              sx={{
                width: mobile ? 0 : undefined,
                flexGrow: 1,
              }}
              justifyContent="center"
              alignItems="center"
            >
              <Box
                component="img"
                src={genderImage}
                sx={{
                  width: '100%',
                  height: mobile ? undefined : '130px',
                  maxWidth: mobile ? '110px' : '130px',
                }}
              />
            </Stack>
          </Stack>
        </Box>
      </Fade>
    </LoadingMessagesContainer>
  );
});

export default SummaryV2Form;

const styles = createStylesUtils({
  container: {
    width: '500px',
    height: '200px',
    position: 'relative',
  },

  containerMobile: {
    width: '335px',
    height: '177px',
    backgroundColor: 'white',
    borderRadius: '24px',
  },

  image: { width: '130px', height: '130px' },

  imageMobile: {},

  membership: {
    color: '#0ABAB5',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
  },

  oneOffPayment: {
    marginTop: '8px',
    color: '#26262680',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '22px',
  },

  sum: {
    marginBottom: '24px',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#262626',
  },

  sumMobile: {
    fontSize: '18px',
    lineHeight: '26px',
  },
});
