import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ContinueRef } from '../../onboarding/types';
import { Fade, Box } from '@mui/material';
import { images } from '../../assets/images';
import LargeInput from '../../onboarding/components/LargeInput';
import { largeInputStyles } from '../../onboarding/components/styles';
import { formStyles } from '../../onboarding/styles';
import analyticsTracker from '../../common/analytics';
import {
  analyticsEvents,
  analyticsScreenNames,
} from '../../common/analyticsEvents';
import { useMobile } from '../../common/hooks';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { onboardingActions } from '../../redux/onboarding';
import { OnboardingV2Step } from '../../redux/onboardingTypes';
import { useSYDMessages } from '../../common/v2/hooks';
import moment from 'moment';
import LoadingMessagesContainer from './LoadingMessagesContainer';
import { useTranslation } from 'react-i18next';
import { checkoutActions } from '../../redux/checkout';
import i18next from '../../i18n';

const CountryForm = forwardRef<ContinueRef, unknown>((_props, ref) => {
  useImperativeHandle(ref, () => ({
    continuePressed: async () => {
      analyticsTracker.track(analyticsEvents.CLICK_CONFIRM_BUTTON, {
        screen: analyticsScreenNames.CITIZENSHIP,
      });

      dispatch(onboardingActions.setOnboardingStep(OnboardingV2Step.GENDER));
    },
  }));

  const dispatch = useAppDispatch();

  useEffect(() => {
    analyticsTracker.track(analyticsEvents.VIEW_CITIZENSHIP_SCREEN);
    dispatch(onboardingActions.setButtonEnabled(true));
  }, [dispatch]);

  const country = useAppSelector((state) => state.checkout.country);
  const hasInitialisedCountry = useAppSelector(
    (state) => state.checkout.hasInitialisedCountry
  );

  const birthDate = useAppSelector(
    (state) => state.checkout.personalData.birthDate
  );
  const sydMessages = useAppSelector((state) => state.onboarding.sydMessages);

  const isMobile = useMobile();

  const [fadeIn, setFadeIn] = useState(false);
  const hasSentMessages = useRef(false);

  const { showMessages } = useSYDMessages();
  const { t } = useTranslation();

  const language = i18next.language;

  useEffect(() => {
    if (hasSentMessages.current) {
      return;
    }
    hasSentMessages.current = true;

    if (sydMessages.map((item) => item.text).includes(birthDate)) {
      setFadeIn(true);
    } else {
      const messages = [
        language === 'ja'
          ? moment(birthDate, 'DD-MM-YYYY').format('YYYY年/MM月/DD日')
          : birthDate,
        t('soYourYearsOld', {
          age: moment().diff(moment(birthDate, 'DD-MM-YYYY'), 'years'),
          sydAge: moment().diff(moment('01-01-2020', 'DD-MM-YYYY'), 'years'),
        }),
        t('whereAreYouFrom'),
      ];

      showMessages(messages, 0, true).then(() => setFadeIn(true));
    }
  }, [birthDate, language, showMessages, sydMessages, t]);

  useEffect(() => {
    if (hasInitialisedCountry) {
      return;
    }

    dispatch(checkoutActions.updateInitialCountry(i18next.language));
  }, [dispatch, hasInitialisedCountry]);

  return (
    <LoadingMessagesContainer>
      <Fade in={fadeIn}>
        <Box
          sx={[
            formStyles.container,
            isMobile
              ? { flex: 1, justifyContent: 'center', alignItems: 'center' }
              : {},
          ]}
        >
          <LargeInput
            placeholder={t('lastName')}
            white={isMobile}
            sx={{
              ...largeInputStyles.countryInput,
              ...(isMobile ? largeInputStyles.countryInputWhiteBorder : {}),
              maxWidth: '700px',
              width: 'calc(100% - 40px)',
              padding: isMobile ? '0px 16px 16px' : '0px 110px 16px',
              ':hover': {
                cursor: 'pointer',
              },
              '& *': {
                cursor: 'pointer',
              },

              '& .Mui-disabled.MuiInput-input': {
                color: isMobile ? 'white' : '#292D32',
                WebkitTextFillColor: isMobile ? 'white' : '#292D32',
              },
              '& .flag': {
                width: '48px',
                height: '48px',
                borderRadius: '24px',
              },
            }}
            value={country.label}
            onChange={() => {}}
            startAdornment={<img className="flag" src={country.flag} />}
            endAdornment={<img src={images.countryArrowDown} />}
            onClick={() => {
              dispatch(
                onboardingActions.setOnboardingStep(
                  OnboardingV2Step.COUNTRY_SELECT
                )
              );
            }}
          />
        </Box>
      </Fade>
    </LoadingMessagesContainer>
  );
});

export default CountryForm;
