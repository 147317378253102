import { Box, FilledTextFieldProps, TextField } from '@mui/material';

export default function RoundBackgroundInput(props: RoundBackgroundInputProps) {
  const {
    endIcon,
    InputProps: { classes, ...restInputProps } = {},
    ...rest
  } = props;

  return (
    <TextField
      variant="filled"
      InputProps={{
        disableUnderline: true,
        classes: {
          root: 'bg-white rounded-text-input w-full px-4 hover:bg-white filled-input',
          input: '!p-4 font-medium',
          ...classes,
        },
        ...(endIcon
          ? { endAdornment: <Box component="img" src={endIcon} /> }
          : {}),
        ...restInputProps,
      }}
      {...rest}
    />
  );
}

type RoundBackgroundInputProps = Omit<FilledTextFieldProps, 'variant'> & {
  endIcon?: string;
};
