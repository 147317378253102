import { Stack, Typography } from '@mui/material';
import { useAppSelector } from '../../redux/store';
import { SYDMesssage } from '../../redux/onboardingTypes';
import { useEffect, useMemo, useState } from 'react';
import { useMobile } from '../../common/hooks';
import { useTextHookUI } from './textHooks';

export default function TextFromSYD(props: Props) {
  const { message } = props;

  const messages = useAppSelector((state) => state.onboarding.sydMessages);
  const question = useAppSelector((state) => state.onboarding.questions);

  const mobile = useMobile();
  const { messagesVisible } = useTextHookUI();

  const lastQuestion = useMemo(() => {
    if (question.length === 0) {
      return undefined;
    }

    return question[question.length - 1];
  }, [question]);

  const [opacityClass, setOpacityClass] = useState(
    'opacity-0 px-0 py-0 max-h-0'
  );

  useEffect(() => {
    if (
      messages.length - message.index <= messagesVisible &&
      (lastQuestion === undefined || !(lastQuestion.finished && mobile))
    ) {
      setTimeout(() => {
        setOpacityClass('opacity-100 px-4 py-3 max-h-[100px]');
      }, 250);
    } else {
      setOpacityClass('opacity-0');
    }
  }, [lastQuestion, message.index, messages.length, messagesVisible, mobile]);

  return (
    <Stack
      justifyContent={'center'}
      alignItems={'center'}
      className={`rounded-[24px] rounded-bl-none ${
        lastQuestion?.finished ? 'bg-[#FFFFFF33]' : 'bg-[#292D32]'
      } transition-all duration-[400ms] ${opacityClass}`}
    >
      <Typography
        className={`text-white text-4 font-medium ${
          lastQuestion?.finished ? 'text-white' : ''
        }`}
      >
        {message.text}
      </Typography>
    </Stack>
  );
}

interface Props {
  message: SYDMesssage;
}
