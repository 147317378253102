import { Fade, Stack, Typography } from '@mui/material';
import { useAppSelector } from '../redux/store';
import TextFromSYD from './chat/TextFromSYD';
import TextFromMe from './chat/TextFromMe';
import { OnboardingV2Step } from '../redux/onboardingTypes';
import { useTranslation } from 'react-i18next';

export default function SYDLeftPanel() {
  const messages = useAppSelector((state) => state.onboarding.sydMessages);
  const onboardingStep = useAppSelector(
    (state) => state.onboarding.onboardingStep
  );

  const { t } = useTranslation();

  return (
    <>
      {onboardingStep === OnboardingV2Step.ANIMATING && (
        <Fade in={true}>
          <Stack
            justifyContent="center"
            alignItems={'center'}
            gap="10px"
            className="w-[400px] mt-[80px] ml-[-150px]"
          >
            <Typography className="font-semibold text-[24px] text-[#292D3233]">
              {t('sydCaresAboutYourPrivacy')}
            </Typography>
          </Stack>
        </Fade>
      )}

      {onboardingStep !== OnboardingV2Step.ANIMATING && (
        <Fade in={true}>
          <Stack
            justifyContent="flex-end"
            alignItems={'flex-start'}
            alignSelf={'flex-end'}
            gap="10px"
            className="w-[400px] mb-[80px] ml-[-150px] max-h-[300px] z-10"
          >
            {messages.map((message) =>
              message.from === 'syd' ? (
                <TextFromSYD message={message} />
              ) : (
                <TextFromMe message={message} />
              )
            )}
          </Stack>
        </Fade>
      )}
    </>
  );
}
