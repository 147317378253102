import { PropsWithChildren } from 'react';
import { useAppSelector } from '../../redux/store';
import { useMobile } from '../../common/hooks';
import { Fade, Stack } from '@mui/material';
import { BeatLoader } from 'react-spinners';

export default function LoadingMessagesContainer(
  props: PropsWithChildren<{ loading?: boolean }>
) {
  const { loading } = props;

  const showingMessages = useAppSelector(
    (state) => state.onboarding.showingMessages
  );

  const mobile = useMobile();

  if (showingMessages || loading) {
    return (
      <Fade in={true} timeout={2000}>
        <Stack className="flex-1 items-center justify-center">
          {mobile ? (
            <BeatLoader color={'white'} />
          ) : (
            <BeatLoader size={20} color={'#7906D4'} />
          )}
        </Stack>
      </Fade>
    );
  }

  return <>{props.children}</>;
}
