import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { shallowEqual } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { appEnvironment, nodeClient } from '../../common/environment';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { CHECKOUT_DATA_KEY, checkoutActions } from '../../redux/checkout';
import { Fade, Box, Stack } from '@mui/material';
import { FADE_TIMEOUT } from '../../common/common';
import { formStyles } from '../../onboarding/styles';
import { ContinueRef } from '../../onboarding/types';
import { onboardingActions } from '../../redux/onboarding';
import { useSYDMessages } from '../../common/v2/hooks';
import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe(appEnvironment.stripeKey);

const StripeForm = forwardRef<ContinueRef, unknown>((_props, ref) => {
  const [clientSecret, setClientSecret] = useState('');
  const navigate = useNavigate();

  const data = useAppSelector((state) => state.checkout, {
    equalityFn: shallowEqual,
  });
  const orderId = useAppSelector((state) => state.checkout.cartV2?.id ?? 0);

  const clientSecretRef = useRef(clientSecret);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (clientSecretRef.current === '') {
      nodeClient
        .post<{ clientSecret: string }>(`checkout/${orderId}/paymentIntent`, {
          name: `${data.personalData.name} ${data.personalData.lastName}`,
          email: data.personalData.email,
        })
        .then(({ data: { clientSecret } }) => {
          if (clientSecret === 'no_intent_required') {
            localStorage.setItem(CHECKOUT_DATA_KEY, JSON.stringify(data));
            setSearchParams(
              `payment_intent=no_intent_required${
                searchParams.get('lng') !== null
                  ? `&lng=${searchParams.get('lng')}`
                  : ''
              }`
            );

            return;
          }

          setClientSecret(clientSecret);
          clientSecretRef.current = clientSecret;
        });
    }
  }, [clientSecret, data, navigate, orderId, searchParams, setSearchParams]);

  if (!clientSecret) {
    return null;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        appearance: {
          theme: 'stripe',

          variables: {
            fontFamily: 'GeneralSans',
            fontWeightNormal: '500',
            fontWeightMedium: '500',
            borderRadius: '20px',
            colorText: '#292D32',
          },

          rules: {
            '.Input': {
              boxShadow: 'none',
              border: '0px solid rgba(41, 45, 50, 0.6)',
              padding: '16px',
              backgroundColor: '#FFFFFF',
            },
            '.Input:focus': {
              boxShadow: '0 0 0 3px #292D32',
            },
            '.Input::placeholder': {
              color: 'rgba(41, 45, 50, 0.6)',
              fontWeight: '500',
            },
            '.Input:focus::placeholder': {
              color: '#292D32',
              fontWeight: '500',
            },
            '.Label': {
              marginTop: '16px',
            },
          },
        },
        clientSecret,
      }}
    >
      <CreditCardForm ref={ref} />
    </Elements>
  );
});

const CreditCardForm = forwardRef<ContinueRef, unknown>((_props, ref) => {
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useAppDispatch();

  const checkoutData = useAppSelector((state) => state.checkout);

  const hasShownMessagesRef = useRef(false);
  const [fadeIn, setFadeIn] = useState(false);

  useImperativeHandle(ref, () => ({
    continuePressed: async () => {
      onContinuePressed();
    },
  }));

  const { t } = useTranslation();

  const onContinuePressed = useCallback(() => {
    if (!stripe || !elements) {
      return;
    }

    dispatch(checkoutActions.setButtonLoading(true));
    localStorage.setItem(CHECKOUT_DATA_KEY, JSON.stringify(checkoutData));

    stripe
      .confirmPayment({
        elements,
        confirmParams: { return_url: window.location.href },
      })
      .finally(() => dispatch(checkoutActions.setButtonLoading(false)));
  }, [checkoutData, dispatch, elements, stripe]);

  const { showMessages } = useSYDMessages();

  useEffect(() => {
    if (!hasShownMessagesRef.current) {
      hasShownMessagesRef.current = true;

      showMessages([t('pleaseFillInYourPaymentDetails')], undefined, true).then(
        () => setFadeIn(true)
      );
    }
  }, [showMessages, t]);

  useEffect(() => {
    dispatch(onboardingActions.setButtonEnabled(true));
  }, [dispatch]);

  return (
    <Fade in={fadeIn} timeout={FADE_TIMEOUT} appear={true}>
      <Box sx={formStyles.container}>
        <Stack justifyContent="center" className="w-full max-w-[576px]">
          <PaymentElement />
        </Stack>
      </Box>
    </Fade>
  );
});

export default StripeForm;
