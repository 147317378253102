import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Step } from '../redux/checkout';

export const useMobile = () => {
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  return mobile;
};

export const useSmallDevice = () => {
  const smallDevice = useMediaQuery('(max-height:700px)');

  return smallDevice;
};

export const useParamStep = (acceptedSteps?: Step[]) => {
  const { step: paramStep } = useParams<{ step: string }>();

  const isAcceptedStep = useMemo(() => {
    if (paramStep) {
      const step = parseInt(paramStep);
      if (
        acceptedSteps === undefined ||
        acceptedSteps.length === 0 ||
        acceptedSteps.includes(step)
      ) {
        return true;
      }
    }

    return false;
  }, [acceptedSteps, paramStep]);

  const step = useMemo<Step>(() => {
    if (paramStep) {
      const step = parseInt(paramStep);
      if (
        acceptedSteps === undefined ||
        acceptedSteps.length === 0 ||
        acceptedSteps.includes(step)
      ) {
        return step;
      } else {
        return acceptedSteps && acceptedSteps.length > 0
          ? acceptedSteps[0]
          : Step.CreateAccount;
      }
    }

    return acceptedSteps && acceptedSteps.length > 0
      ? acceptedSteps[0]
      : Step.CreateAccount;
  }, [acceptedSteps, paramStep]);

  return { step, isAcceptedStep };
};
