import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Country } from '../../data/countries';
import { countryStyles } from './styles';

export default function CountryItem(props: Props) {
  const {
    country: { phone, flag, label },
    onSelected,
    hidePhoneCode,
  } = props;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        marginTop: '24px',
        '& .flag': {
          width: '36px',
          height: '36px',
          borderRadius: '18px',
          marginRight: '12px',
        },
      }}
      onClick={() => onSelected(props.country)}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          alignSelf: 'center',
          ':hover': {
            opacity: 0.7,
            cursor: 'pointer',
          },
        }}
      >
        <img src={flag} className="flag" />
        {!hidePhoneCode && (
          <Typography sx={countryStyles.phone}>+{phone}</Typography>
        )}
        <Typography sx={countryStyles.countryLabel} variant="body1">
          {label}
        </Typography>
      </Stack>
    </Stack>
  );
}

interface Props {
  hidePhoneCode?: boolean;
  country: Country;
  onSelected: (country: Country) => void;
}
