import { Stack, Typography } from '@mui/material';
import { images } from '../../assets/images';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { nextQuestion, onboardingActions } from '../../redux/onboarding';
import { useAppDispatch } from '../../redux/store';
import { OnboardingContinueRef } from '../../redux/onboardingTypes';
import { useTranslation } from 'react-i18next';

const LQIVideoViewMobile = forwardRef<OnboardingContinueRef, unknown>(
  (_props, ref) => {
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      onContinuePressed: async () => {
        await dispatch(nextQuestion());
      },
    }));

    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(onboardingActions.setButtonEnabled(true));
    }, [dispatch]);

    return (
      <Stack
        flex={1}
        justifyContent={'center'}
        alignItems={'center'}
        className="px-[80px] relative"
      >
        <video
          src={images.v2.onboarding.lqiVideo}
          muted={false}
          controls={false}
          loop={true}
          autoPlay={true}
          playsInline={true}
        />

        <Stack className="absolute top-0 right-0 bottom-0 left-0 justify-center items-center">
          <Typography className="text-white font-semibold text-[16px] bg-[#FFFFFF33] p-[20px] rounded-full">
            {t('calculatingYourLQI')}
          </Typography>
        </Stack>
      </Stack>
    );
  }
);

export default LQIVideoViewMobile;
