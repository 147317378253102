import { useMemo, TouchEvent, MouseEvent, useCallback } from 'react';
import { useAppSelector } from '../../redux/store';
import { useParams } from 'react-router-dom';

export const useShoppingCart = () => {
  const cart = useAppSelector((state) => state.checkout.cartV2);

  const price = useMemo(() => {
    if (!cart || cart.items.length === 0) {
      return 0;
    }
    if (cart.items[0].subscription !== undefined) {
      return cart.items[0].display_price / 100;
    }
    return cart.display_price / 100;
  }, [cart]);

  const isFree = useMemo(() => {
    if (!cart || cart.items.length === 0) {
      return false;
    }

    return price === 0;
  }, [cart, price]);

  const isPremium = useMemo(() => {
    if (!cart || cart.items.length === 0) {
      return false;
    }

    return cart.items[0].product.code === 'complete';
  }, [cart]);

  const isSubscription = useMemo(() => {
    if (!cart || cart.items.length === 0) {
      return false;
    }

    return cart.items[0].subscription !== undefined;
  }, [cart]);

  return { isFree, price, isPremium, isSubscription };
};

export const isMouseEvent = (
  event: MouseEvent | TouchEvent
): event is MouseEvent => {
  return (event as MouseEvent).pageX !== undefined;
};

export const rectContains = (rect: DOMRect, x: number, y: number) => {
  return (
    x >= rect.x &&
    x <= rect.x + rect.width &&
    y >= rect.y &&
    y <= rect.y + rect.height
  );
};

export const useQuestionNavigation = () => {
  const questions = useAppSelector((state) => state.onboarding.questions);
  const { index } = useParams<{ index: string }>();

  const parsedIndex = useMemo(() => {
    if (!index) {
      return 0;
    }
    const parsed = parseInt(index);
    if (isNaN(parsed)) {
      return 0;
    }
    return parsed;
  }, [index]);

  const isFinished = useMemo(() => {
    if (questions.length > 0 && parsedIndex >= questions.length) {
      return questions[questions.length - 1].finished;
    }

    return false;
  }, [parsedIndex, questions]);

  const currentQuestion = useMemo(() => {
    if (questions.length === 0) {
      return undefined;
    } else if (isFinished) {
      return questions[questions.length - 1];
    } else {
      return questions[parsedIndex];
    }
  }, [isFinished, parsedIndex, questions]);

  const goNext = useCallback(() => {}, []);

  return { currentQuestion, goNext };
};
