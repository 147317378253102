import { Stack, Typography } from '@mui/material';
import { SystemStyleObject, Theme } from '@mui/system';
import { images } from '../../../assets/images';
import { createStylesUtils } from '../../../common/common';
import { useMobile } from '../../../common/hooks';
import { OnboardingAnswer } from '../../../redux/onboardingTypes';

export default function QuestionCell(props: Props) {
  const { sx = {}, selected, answer, onSelected } = props;
  const mobile = useMobile();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={[
        styles.container,
        sx,
        mobile ? styles.containerMobile : {},
        selected ? styles.selected : {},
      ]}
      onClick={() => onSelected(answer)}
    >
      <Typography sx={[styles.gender, mobile ? { color: 'white' } : {}]}>
        {answer.text}
      </Typography>

      {selected && (
        <img
          className={
            mobile ? 'w-[24px] h-[24px] absolute top-0 end-0' : 'selected'
          }
          src={images.checkSelected}
        />
      )}
    </Stack>
  );
}

interface Props {
  sx?: SystemStyleObject<Theme>;
  selected: boolean;
  answer: OnboardingAnswer;
  onSelected: (answer: OnboardingAnswer) => void;
}

const styles = createStylesUtils({
  container: {
    flexBasis: '160px',
    maxWidth: '160px',
    width: '160px',
    height: '160px',

    flexShrink: 0,
    flexGrow: 0,

    borderRadius: '36px',
    background: 'white',
    boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    border: '4px solid transparent',
    boxSizing: 'border-box',

    ':hover': {
      cursor: 'pointer',
    },

    '& .selected': {
      position: 'absolute',
      top: '-10px',
      right: '-10px',
    },
  },

  containerMobile: {
    flex: 1,
    height: '74px',
    borderRadius: '24px',
    backgroundColor: '#656769',
  },

  selected: {
    border: '4px solid #0ABAB5',
  },

  gender: {
    color: '#292D32',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: '600',
    maxWidth: '100px',
    textAlign: 'center',
  },
});
