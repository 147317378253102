import { ReactQrCode } from '@devmehq/react-qr-code';
import { useGetUserProfileQuery } from '../api/api';

export const useQRLink = () => {
  const { data } = useGetUserProfileQuery();

  if (!data) {
    return null;
  }

  return `https://yzk5e.app.link/Sozh5Tqwcub?email=${data.email}&signIn=true`;
};

export default function QRCode(props: QRCodeProps) {
  const { size } = props;

  const link = useQRLink();
  if (!link) {
    return null;
  }

  return <ReactQrCode size={size !== undefined ? size : 200} value={link} />;
}

interface QRCodeProps {
  size?: number;
}
