import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useMobile } from '../../common/hooks';
import { genderStyles } from '../../onboarding/components/GenderCell';
import { images } from '../../assets/images';

export default function GenderCellV2(props: Props) {
  const { selected, gender, imageSrc, onSelected, sx } = props;

  const mobile = useMobile();

  const className = useMemo(() => {
    if (selected) {
      return 'bg-[#0abab533] border-[#0ABAB5]';
    } else {
      return `${mobile ? 'bg-[#FFFFFF66]' : 'bg-[#EBEDF0]'} border-transparent`;
    }
  }, [mobile, selected]);

  return (
    <Stack
      gap="15px"
      alignItems="center"
      className="hover:cursor-pointer relative"
      onClick={onSelected}
      sx={sx}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        className={`${mobile ? 'w-[80px]' : 'w-[200px]'} ${
          mobile ? 'h-[80px]' : 'h-[200px]'
        } rounded-full border-4 relative ${className}`}
      >
        <img src={imageSrc} className="w-full h-full" />

        {selected && (
          <Box
            component="img"
            className="absolute bottom-[-15px] left-[50% - 15px] w-[30px] h-[30px]"
            src={images.checkSelected}
          />
        )}
      </Stack>

      <Typography
        sx={[
          genderStyles.gender,
          mobile
            ? { fontSize: '14px', lineHeight: '18px', color: 'white' }
            : {},
        ]}
      >
        {gender}
      </Typography>
    </Stack>
  );
}

interface Props {
  selected?: boolean;
  sx?: SxProps<Theme>;

  imageSrc: string;
  gender: string;
  onSelected: () => void;
}
