import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    headerSubtitle: React.CSSProperties;
    captcha: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    headerSubtitle?: React.CSSProperties;
    captcha?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    headerSubtitle: true;
    captcha: true;
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: 'GeneralSans',

    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
      color: 'rgba(38, 38, 38, 0.5)',
    },

    headerSubtitle: {
      fontSize: 14,
      color: '#292D32',
      fontWeight: '500',
    },

    body1: {
      color: '#413F46',
      fontSize: '14px',
      fontWeight: 400,
    },

    body2: {
      color: '#A09FA3',
      fontSize: '10px',
      fontWeight: 500,
    },

    captcha: {
      color: '#A09FA3',
      fontSize: '12px',
      fontWeight: 500,
    },

    h1: {
      fontWeight: 600,
      fontSize: '64px',
      lineHeight: '64px',
    },

    h3: {
      color: '#262626',
      fontSize: 24,
      fontWeight: 600,
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 0,
          lineHeight: '24px',

          '& .MuiLoadingButton-loadingIndicator': {
            color: '#ffffff',
          },

          '&.Mui-disabled': {
            color: '#ffffff',
            background: 'rgba(41, 45, 50, 0.4)',
          },
        },

        contained: {
          background: '#292D32',
          borderRadius: '24px',
          padding: '12px 24px',
          boxShadow: '0px 10px 24px rgba(41, 45, 50, 0.2)',
          textTransform: 'none',

          '&:hover': {
            opacity: 0.7,
            background: '#292D32',
            boxShadow: '0px 10px 24px rgba(41, 45, 50, 0.2)',

            '@media (hover: none)': {
              opacity: 1,
            },
          },
        },

        endIcon: {
          '& img': {
            width: '24px',
            height: '24px',
          },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: '100%',
          borderRadius: '20px',
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: '3px solid #292D32',
            },
            '& .MuiOutlinedInput-input::placeholder': {
              opacity: 1,
              color: '#292D32',
            },
          },
        },
        input: {
          fontFamily: 'GeneralSans',
          fontSize: '14px',
          fontWeight: 500,
          color: '#292D32',

          '::placeholder': {
            color: 'rgba(41, 45, 50, 0.6)',
            opacity: 1,
            fontWeight: 500,
          },
        },
        notchedOutline: {
          border: '1.0px solid rgba(41, 45, 50, 0.6)',
        },
      },
    },
  },
});
