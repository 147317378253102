import { Stack, Typography } from '@mui/material';
import { SystemStyleObject, Theme } from '@mui/system';
import { images } from '../../assets/images';
import { createStylesUtils } from '../../common/common';
import { useMobile } from '../../common/hooks';

export default function GenderCell(props: Props) {
  const { sx = {}, selected, imageSrc, gender, onSelected } = props;
  const mobile = useMobile();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={[
        styles.container,
        sx,
        mobile ? { width: '140px', height: '140px' } : {},
        selected ? styles.selected : {},
        {
          '& .gender': {
            width: mobile ? '80px' : '150px',
            height: mobile ? '80px' : '150px',
          },
        },
      ]}
      onClick={onSelected}
    >
      <img src={imageSrc} className="gender" />

      <Typography
        sx={[
          styles.gender,
          mobile ? { fontSize: '14px', lineHeight: '18px' } : {},
        ]}
      >
        {gender}
      </Typography>

      {selected && <img className="selected" src={images.checkSelected} />}
    </Stack>
  );
}

interface Props {
  sx?: SystemStyleObject<Theme>;
  selected?: boolean;

  imageSrc: string;
  gender: string;
  onSelected: () => void;
}

const styles = createStylesUtils({
  container: {
    width: '240px',
    height: '240px',
    borderRadius: '36px',
    background: 'white',
    boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    border: '4px solid transparent',

    ':hover': {
      cursor: 'pointer',
    },

    '& .selected': {
      position: 'absolute',
      top: '-10px',
      right: '-10px',
    },
  },

  selected: {
    border: '4px solid #0ABAB5',
  },

  gender: {
    color: '#292D32',
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: '600',
  },
});

export const genderStyles = styles;
