import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { images } from '../../assets/images';
import { Box, Fade, Input, Stack, Typography } from '@mui/material';
import { useAppDispatch } from '../../redux/store';
import { onboardingActions } from '../../redux/onboarding';
import { useTranslation } from 'react-i18next';

export default function QuestionInputViewMobile(props: Props) {
  const { t } = useTranslation();
  const { selection, onRemoved, onAdded, onCustomAnswerEntered } = props;

  const [customAnswer, setCustomAnswer] = useState('');

  const ref = useRef<HTMLDivElement>(null);
  const prevSelection = useRef(selection);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selection.length > prevSelection.current.length && ref.current) {
      ref.current.scrollTo({ left: ref.current.getBoundingClientRect().width });
    }

    prevSelection.current = selection;
  }, [selection]);

  const hasInvalidAnswers = useMemo(() => {
    return selection.find((item) => !item.valid) !== undefined;
  }, [selection]);

  const className = useMemo(() => {
    if (selection.length === 0) {
      if (customAnswer.length === 0) {
        return 'border-transparent';
      } else {
        return 'border-text';
      }
    } else {
      return hasInvalidAnswers ? 'border-[#E84D8A]' : 'border-[#0ABAB5]';
    }
  }, [customAnswer.length, hasInvalidAnswers, selection.length]);

  useEffect(() => {
    dispatch(
      onboardingActions.setButtonEnabled(
        selection.length > 0 && !hasInvalidAnswers
      )
    );
  }, [dispatch, hasInvalidAnswers, selection.length]);

  const selectedItem = useCallback(
    (answer: SuggestionAnswer) => {
      const backgroundClassName = answer.valid
        ? 'bg-[#0ABAB533]'
        : 'bg-[#E84D8A33]';
      const textClassName = answer.valid ? 'text-[#0ABAB5]' : 'text-[#E84D8A]';

      return (
        <Stack
          className={`flex-row items-center py-[4px] px-[16px] pr-[8] rounded-[36px] shrink-0 ${backgroundClassName}`}
          key={answer.text}
        >
          <Typography className={`font-medium text-[14px] ${textClassName}`}>
            {answer.text}
          </Typography>

          <Box
            onClick={() => onRemoved(answer)}
            component="img"
            src={images.v2.onboarding.gridSelectionRemove}
            className="w-[20px] h-[20px]"
          />
        </Stack>
      );
    },
    [onRemoved]
  );

  return (
    <Stack className="self-stretch">
      <Stack
        className={`self-stretch mx-[20px] p-[16px] rounded-full border-[2px] bg-white overflow-x-scroll ${className}`}
        ref={ref}
        sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
      >
        <Stack className={`gap-[4px] flex-row items-center self-stretch`}>
          {selection.map((item) => selectedItem(item))}

          <Input
            disableUnderline={true}
            className="font-medium min-w-[80px]"
            onChange={(e) => setCustomAnswer(e.target.value)}
            value={customAnswer}
            placeholder={selection.length === 0 ? t('typeInYourOwnAnswer') : ''}
            onKeyUp={(e) => {
              if (e.key === 'Enter' && customAnswer.length > 0) {
                onCustomAnswerEntered(customAnswer).then((valid) =>
                  onAdded({ text: customAnswer, valid })
                );

                setCustomAnswer('');
              }
            }}
          />
        </Stack>
      </Stack>

      {hasInvalidAnswers && (
        <Fade in={true}>
          <Typography
            className={`text-[10px] font-medium text-[#A09FA3] self-center mt-[10px] mx-[16px] text-center ${
              hasInvalidAnswers ? 'opacity-100' : 'opacity-0'
            }`}
          >
            {t('sorryButItDoesntLook')}
          </Typography>
        </Fade>
      )}
    </Stack>
  );
}

interface Props {
  selection: SuggestionAnswer[];

  onRemoved: (answer: SuggestionAnswer) => void;
  onAdded: (answer: SuggestionAnswer) => void;

  onCustomAnswerEntered: (answer: string) => Promise<boolean>;
}

export interface SuggestionAnswer {
  id?: string;
  text: string;
  valid: boolean;
}
