import { useCallback, useMemo, useState } from 'react';
import { Fade, Stack, InputAdornment } from '@mui/material';
import { images } from '../../assets/images';
import CountryItem from '../../onboarding/components/CountryItem';
import LargeInput from '../../onboarding/components/LargeInput';
import { largeInputStyles } from '../../onboarding/components/styles';
import { useGetCountriesQuery } from '../../api/api';
import { Country } from '../../data/countries';
import { checkoutActions } from '../../redux/checkout';
import { useAppDispatch } from '../../redux/store';
import { onboardingActions } from '../../redux/onboarding';
import { OnboardingV2Step } from '../../redux/onboardingTypes';
import { useTranslation } from 'react-i18next';

export default function SelectCountryForm() {
  const dispatch = useAppDispatch();
  const [countryQuery, setCountryQuery] = useState<string>();

  const { data: countries } = useGetCountriesQuery(false);

  const filteredCountries = useMemo(() => {
    if (!countryQuery || !countries) {
      return [];
    }

    return countries
      .filter((item) =>
        item.label.toUpperCase().startsWith(countryQuery.toUpperCase())
      )
      .slice(0, 3);
  }, [countries, countryQuery]);

  const onCountrySelected = useCallback(
    (country: Country) => {
      dispatch(checkoutActions.setCountry(country));
      dispatch(onboardingActions.setOnboardingStep(OnboardingV2Step.COUNTRY));
    },
    [dispatch]
  );

  const { t } = useTranslation();

  return (
    <Fade in={true}>
      <Stack
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          position: 'relative',
          width: '100%',
          flex: 1,
        }}
      >
        <Stack sx={[largeInputStyles.container, {}]} className="mt-[180px] ">
          <LargeInput
            placeholder={t('typeInCountryName')}
            sx={largeInputStyles.countryInput}
            value={countryQuery}
            onChange={(e) => setCountryQuery(e.target.value)}
            startAdornment={
              <InputAdornment
                position="start"
                sx={largeInputStyles.countryStartAdornment}
              >
                <img src={images.form.country} />
              </InputAdornment>
            }
            endAdornment={<img src={images.form.clear} />}
          />
        </Stack>

        <Stack sx={{ height: '180px' }}>
          {filteredCountries.map((item) => (
            <CountryItem
              country={item}
              onSelected={onCountrySelected}
              hidePhoneCode={true}
            />
          ))}
        </Stack>
      </Stack>
    </Fade>
  );
}
