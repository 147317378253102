import { Stack, Typography } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import analyticsTracker from '../../../common/analytics';
import {
  analyticsEvents,
  analyticsScreenNames,
} from '../../../common/analyticsEvents';
import { createStylesUtils } from '../../../common/common';
import { nextQuestion } from '../../../redux/onboarding';
import { OnboardingContinueRef } from '../../../redux/onboardingTypes';
import { useAppDispatch } from '../../../redux/store';
import { useQuestionNavigation } from '../../common/hooks';

const BmiForm = forwardRef<OnboardingContinueRef, Props>((props, ref) => {
  useImperativeHandle(ref, () => ({
    onContinuePressed: async () => {
      analyticsTracker.track(analyticsEvents.CLICK_CONFIRM_BUTTON, {
        screen: analyticsScreenNames.BMI,
      });

      dispatch(nextQuestion()).then(goNext);
    },
  }));

  const { goNext } = useQuestionNavigation();

  useEffect(() => {
    analyticsTracker.track(analyticsEvents.VIEW_BMI_SCREEN);
  }, []);

  const dispatch = useAppDispatch();
  const { bmi, info } = props;

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={styles.container}
    >
      <Typography sx={styles.bmi}>{bmi}</Typography>
      <Typography sx={styles.info}>{info}</Typography>
    </Stack>
  );
});

const styles = createStylesUtils({
  container: {
    width: 'calc(100% - 40px)',
    maxWidth: '395px',
    minHeight: '226px',
    boxSizing: 'border-box',
    padding: '40px',
    background:
      'linear-gradient(180deg, rgba(10, 186, 181, 0) 0%, rgba(10, 186, 181, 0.05) 100%), rgba(255, 255, 255, 0.2)',
    boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.1)',
    borderRadius: '36px',
    position: 'relative',
  },

  bmi: {
    color: '#0ABAB5',
    fontSize: '64px',
    lineHeight: '64px',
    fontWeight: '600',
  },

  info: {
    textAlign: 'center',
    color: '#292D32',
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '500',
    marginTop: '60px',
  },
});

interface Props {
  bmi: string;
  info: string;
}

export default BmiForm;
