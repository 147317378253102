import { Stack } from '@mui/material';
import {
  OnboardingContinueRef,
  OnboardingQuestion,
} from '../../redux/onboardingTypes';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import GridFormItem from './GridFormItem';
import GridFormSelection, { SuggestionAnswer } from './GridFormSelection';
import analyticsTracker from '../../common/analytics';
import {
  analyticsEvents,
  analyticsScreenNames,
} from '../../common/analyticsEvents';
import { answerQuestion } from '../../redux/onboarding';
import { useAppDispatch } from '../../redux/store';

const GridFormV2 = forwardRef<OnboardingContinueRef, Props>((props, ref) => {
  useImperativeHandle(ref, () => ({
    onContinuePressed: async () => {
      analyticsTracker.track(
        analyticsEvents.CLICK_CONFIRM_BUTTON,
        analyticsScreenPayload
      );

      dispatch(
        answerQuestion({
          answers: selection.map((item) => item.text),
          question_id: question.question_id,
        })
      );
    },
  }));

  const { question, onCustomerAnswerEntered } = props;

  const [selection, setSelection] = useState<SuggestionAnswer[]>([]);
  const dispatch = useAppDispatch();

  const sectionedAnswers = useMemo(() => {
    const half = Math.floor(question.answers.length / 2);

    return {
      top: question.answers.slice(0, half),
      bottom: question.answers.slice(half),
    };
  }, [question.answers]);

  const analyticsScreenPayload = useMemo(() => {
    return {
      screen: analyticsScreenNames.QUESTION_SUGGESTION,
      question: question.question_text,
    };
  }, [question.question_text]);

  const item = useCallback(
    (answer: SuggestionAnswer) => {
      return (
        <GridFormItem
          key={answer.id}
          text={answer.text}
          selected={
            selection.find((item) => item.text === answer.text) !== undefined
          }
          onSelected={() => {
            if (
              selection.find((item) => item.text === answer.text) !== undefined
            ) {
              setSelection((prev) =>
                prev.filter((item) => item.text !== answer.text)
              );
            } else {
              setSelection((prev) => [...prev, answer]);
            }
          }}
        />
      );
    },
    [selection]
  );

  return (
    <Stack gap="40px" className="w-full">
      <Stack
        direction="row"
        flexWrap="wrap"
        columnGap="40px"
        rowGap="40px"
        alignSelf="center"
        justifyContent="center"
        className="max-w-[70%]"
      >
        {sectionedAnswers.top.map((answer) =>
          item({ id: answer.id, text: answer.text, valid: true })
        )}
      </Stack>

      <GridFormSelection
        selection={selection}
        onRemoved={(answer) =>
          setSelection((prev) => prev.filter((item) => item !== answer))
        }
        onAdded={(answer) => setSelection((prev) => [...prev, answer])}
        onCustomAnswerEntered={onCustomerAnswerEntered}
      />

      <Stack
        direction="row"
        flexWrap="wrap"
        columnGap="40px"
        rowGap="40px"
        justifyContent="center"
        alignSelf="center"
        className="max-w-[70%]"
      >
        {sectionedAnswers.bottom.map((answer) =>
          item({ id: answer.id, text: answer.text, valid: true })
        )}
      </Stack>
    </Stack>
  );
});

interface Props {
  question: OnboardingQuestion;

  onCustomerAnswerEntered: (answer: string) => Promise<boolean>;
}

export default GridFormV2;
