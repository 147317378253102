import { Stack, Typography } from '@mui/material';
import { images } from '../../../assets/images';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { OnboardingContinueRef } from '../../../redux/onboardingTypes';
import { useAppDispatch } from '../../../redux/store';
import { nextQuestion, onboardingActions } from '../../../redux/onboarding';
import { useTranslation } from 'react-i18next';

const LQIVideoView = forwardRef<OnboardingContinueRef, unknown>(
  (_props, ref) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useImperativeHandle(ref, () => ({
      onContinuePressed: async () => {
        dispatch(nextQuestion());
      },
    }));

    useEffect(() => {
      dispatch(onboardingActions.setButtonEnabled(true));
    }, [dispatch]);

    return (
      <Stack
        flex={1}
        justifyContent={'center'}
        alignItems={'center'}
        className=" relative"
      >
        <video
          src={images.v2.onboarding.lqiVideoDesktop}
          muted={false}
          controls={false}
          loop={true}
          autoPlay={true}
          playsInline={true}
        />

        <Stack className="absolute top-0 right-0 bottom-0 left-0 justify-center items-center">
          <Typography className="text-[#262626] font-semibold text-[24px] bg-[#FFFFFF66] p-[20px] rounded-full">
            {t('calculatingYourLQI')}
          </Typography>
        </Stack>
      </Stack>
    );
  }
);

export default LQIVideoView;
