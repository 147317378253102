import { useCallback } from 'react';
import { useAppDispatch } from '../../redux/store';
import { onboardingActions } from '../../redux/onboarding';
import { appEnvironment } from '../environment';

export const TIMEOUT = appEnvironment.timeout;

export const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const useSYDMessages = () => {
  const dispatch = useAppDispatch();

  const showMessages = useCallback(
    async (
      messages: string[],
      indexFromUser?: number,
      showLoadingMessages?: boolean
    ) => {
      if (showLoadingMessages) {
        dispatch(onboardingActions.setShowingMessages(true));
      }

      for (let i = 0; i < messages.length; i++) {
        dispatch(
          onboardingActions.addMessage({
            text: messages[i],
            type: 'normal',
            from: i === indexFromUser ? 'user' : 'syd',
          })
        );

        await wait(TIMEOUT);
      }

      if (showLoadingMessages) {
        dispatch(onboardingActions.setShowingMessages(false));
      }

      return {};
    },
    [dispatch]
  );

  return { showMessages };
};
