import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function HeaderProgressBarMobile(props: Props) {
  const { label, step, totalSteps, subtext, white, rightMargin } = props;

  const { t } = useTranslation();

  const firstWidth = useMemo(() => {
    const progress = (step / totalSteps) * 100;
    const width = (progress / 33) * 100;

    return width > 100 ? 100 : width;
  }, [step, totalSteps]);

  const secondWidth = useMemo(() => {
    const progress = (step / totalSteps) * 100 - 33;
    const width = (progress / 33) * 100;

    return width < 0 ? 0 : width > 100 ? 100 : width;
  }, [step, totalSteps]);

  const thirdWidth = useMemo(() => {
    const progress = (step / totalSteps) * 100 - 66;
    const width = (progress / 33) * 100;

    return width < 0 ? 0 : width;
  }, [step, totalSteps]);

  return (
    <Stack
      className={`absolute left-[80px] mt-[20px] items-center justify-center ${
        rightMargin ? 'right-[80px]' : 'right-[20px]'
      }`}
    >
      <Stack className="items-center self-stretch">
        <Typography className="text-[#262626] text-[18px] font-semibold mb-[10px]">
          {label}
        </Typography>

        <Stack className="flex-row gap-x-[10px] self-stretch">
          <Box
            className="max-w-[172px] h-[6px] rounded-[36px] relative w-[30%]"
            sx={{ backgroundColor: white ? 'white' : '#f5f6f6' }}
          >
            <Box
              className="absolute left-0 top-0 bottom-0 rounded-[36px] bg-text"
              sx={{ width: `${firstWidth}%` }}
            />
          </Box>

          <Box
            className="max-w-[172px] h-[6px] rounded-[36px relative w-[30%]"
            sx={{ backgroundColor: white ? 'white' : '#f5f6f6' }}
          >
            <Box
              className="absolute left-0 top-0 bottom-0 rounded-[36px] bg-text"
              sx={{ width: `${secondWidth}%` }}
            />
          </Box>

          <Box
            className="max-w-[172px] h-[6px] rounded-[36px] relative w-[30%]"
            sx={{ backgroundColor: white ? 'white' : '#f5f6f6' }}
          >
            <Box
              className="absolute left-0 top-0 bottom-0 rounded-[36px] bg-text"
              sx={{ width: `${thirdWidth}%` }}
            />
          </Box>
        </Stack>

        <Typography className="mt-[8px] text-[14px] font-medium text-[#26262680]">
          {subtext
            ? subtext
            : t('headerStep', {
                step,
                totalSteps,
              })}
        </Typography>
      </Stack>
    </Stack>
  );
}

export interface Props {
  label: string;
  step: number;
  totalSteps: number;

  subtext?: string;
  white?: boolean;
  rightMargin?: boolean;
}
