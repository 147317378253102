import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Slider, Stack, Typography } from '@mui/material';
import {
  OnboardingContinueRef,
  OnboardingQuestion,
} from '../../../redux/onboardingTypes';
import { createStylesUtils } from '../../../common/common';
import { useAppDispatch } from '../../../redux/store';
import { onboardingActions } from '../../../redux/onboarding';
import { answerQuestion } from '../../../redux/onboarding';
import { useMobile } from '../../../common/hooks';
import {
  analyticsEvents,
  analyticsScreenNames,
} from '../../../common/analyticsEvents';
import analyticsTracker from '../../../common/analytics';
import { useQuestionNavigation } from '../../common/hooks';
import QuestionTooltip from '../../../components_v2/questions/QuestionTooltip';

const QuestionSliderForm = forwardRef<OnboardingContinueRef, Props>(
  (props, ref) => {
    useImperativeHandle(ref, () => ({
      onContinuePressed: async () => {
        analyticsTracker.track(
          analyticsEvents.CLICK_CONFIRM_BUTTON,
          analyticsScreenPayload
        );

        dispatch(
          answerQuestion({
            answers: [question.answers[selected].id],
            question_id: question.question_id,
          })
        ).then(goNext);
      },
    }));

    const { goNext } = useQuestionNavigation();

    const { question } = props;
    const [selected, setSelected] = useState(0);
    const selectedRef = useRef<number>();

    useEffect(() => {
      if (question.answer !== '') {
        setSelected(
          question.answers.findIndex((item) => item.id === question.answer)
        );
      }
    }, [question.answer, question.answers]);

    const analyticsScreenPayload = useMemo(() => {
      return {
        screen: analyticsScreenNames.QUESTION_TILE,
        question: question.question_text,
      };
    }, [question.question_text]);

    useEffect(() => {
      analyticsTracker.track(
        analyticsEvents.VIEW_QUESTION_SLIDER_SCREEN,
        analyticsScreenPayload
      );
    }, [analyticsScreenPayload]);

    useEffect(() => {
      if (selectedRef.current !== selected) {
        selectedRef.current = selected;

        analyticsTracker.track(analyticsEvents.CLICK_QUESTION_SLIDER, {
          ...analyticsScreenPayload,
          selectedQuestionId: question.answers[selected].id,
          selectedQuestionText: question.answers[selected].text,
        });
      }
    }, [analyticsScreenPayload, question.answers, selected]);

    const dispatch = useAppDispatch();
    useEffect(() => {
      dispatch(onboardingActions.setButtonEnabled(true));
    }, [dispatch]);

    const markers = useMemo(() => {
      const max = question.answers.length - 1;

      const result = [0.1];
      for (let i = 1; i < max; i++) {
        result.push(i);
      }
      result.push(max - 0.1);

      return result;
    }, [question.answers.length]);

    const mobile = useMobile();

    return (
      <Stack
        sx={{ width: '100%' }}
        flex={mobile ? 1 : undefined}
        gap="40px"
        alignItems="center"
        justifyContent={mobile ? 'center' : undefined}
      >
        <Stack
          direction="column"
          alignItems="center"
          alignSelf="stretch"
          rowGap={mobile ? '20px' : '30px'}
        >
          <Typography
            sx={[
              styles.answerText,
              mobile ? { color: 'white', fontSize: '16px' } : {},
            ]}
          >
            {selected >= question.answers.length
              ? question.answers[0].text
              : question.answers[selected].text}
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            spacing={mobile ? '20px' : '30px'}
            alignSelf="stretch"
            justifyContent="center"
          >
            {!mobile ? (
              <Typography sx={styles.label}>
                {question.answers[0].text}
              </Typography>
            ) : (
              <div />
            )}
            <Slider
              min={0}
              step={1}
              max={question.answers.length - 1}
              marks={markers.map((item) => ({ value: item }))}
              sx={[
                styles.slider,
                mobile
                  ? {
                      height: '5px',

                      '& .MuiSlider-mark': {
                        backgroundColor: '#FFFFFF66',
                        zIndex: 1,
                        height: '12px',
                        marginTop: '-36px',
                      },
                    }
                  : {},
              ]}
              value={selected >= question.answers.length ? 0 : selected}
              onChange={(_event, value) => setSelected(value as number)}
            />
            {!mobile ? (
              <Typography sx={styles.label}>
                {question.answers[question.answers.length - 1].text}
              </Typography>
            ) : (
              <div />
            )}
          </Stack>

          {mobile && (
            <Stack
              direction="row"
              alignSelf="stretch"
              justifyContent="space-between"
              sx={{ margin: '0px 20px' }}
            >
              <Typography sx={[styles.label, mobile ? { color: 'white' } : {}]}>
                {question.answers[0].text}
              </Typography>
              <Typography sx={[styles.label, mobile ? { color: 'white' } : {}]}>
                {question.answers[question.answers.length - 1].text}
              </Typography>
            </Stack>
          )}
        </Stack>

        {!mobile && <QuestionTooltip question={question} />}
      </Stack>
    );
  }
);

const styles = createStylesUtils({
  slider: {
    flexGrow: 1,
    maxWidth: '540px',
    height: '20px',
    color: 'transparent',
    background: 'rgba(255, 255, 255, 0.3)',
    border: '10px solid #FFFFFF',
    borderRadius: '36px',

    '& .MuiSlider-thumb': {
      backgroundColor: 'white',
      width: '50px',
      height: '50px',
      border: '20px solid #262626',
      zIndex: 10,
    },
    '& .MuiSlider-mark': {
      backgroundColor: '#292D3266',
      height: '20px',
      marginTop: '-40px',
      zIndex: -100,
    },
  },

  label: {
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#292D3266',
  },

  answerText: {
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#262626',
  },
});

export default QuestionSliderForm;

interface Props {
  question: OnboardingQuestion;
}
