import { Box, Stack, Typography } from '@mui/material';
import { images } from '../../assets/images';
import { OnboardingQuestion } from '../../redux/onboardingTypes';

export default function QuestionTooltip(props: Props) {
  const {
    question: { insight },
  } = props;

  return (
    <Stack>
      <Box
        component="img"
        src={images.v2.onboarding.tooltipTriangle}
        alignSelf="center"
        className="translate-y-[3px]"
      />

      <Stack
        direction="row"
        gap="12px"
        alignItems="flex-start"
        className="bg-white p-5 rounded-[18px] max-w-[400px]"
      >
        <Typography className="text-[36px] font-medium">💡</Typography>

        <Typography className="text-[10px] font-medium text-[#292D3266]">
          {insight}
        </Typography>
      </Stack>
    </Stack>
  );
}

interface Props {
  question: OnboardingQuestion;
}
