import React, { useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store, useAppDispatch, useAppSelector } from './redux/store';
import { Amplify, Auth } from 'aws-amplify';
import {
  checkoutActions,
  CONTINUE_HERE_SELECTED,
  NAVIGATION_STATE_KEY,
} from './redux/checkout';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { appEnvironment, environments, mlClient } from './common/environment';
import {
  accessTokenHeaders,
  anayticsIdentifyUser,
} from './redux/onboardingActions';
import './general-sans.css';
import './App.css';
import LoggedOutV2 from './LoggedOutV2';
import LoggedInV2 from './LoggedInV2';
import { useMobile } from './common/hooks';
import LoggedOutV2Mobile from './mobile/LoggedOutV2Mobile';
import LoggedInV2Mobile from './mobile/LoggedInV2Mobile';
import Hotjar from '@hotjar/browser';
import './i18n';
import i18next from './i18n';
import { api } from './api/api';
//...
import * as Sentry from '@sentry/react';
import { onboardingActions } from './redux/onboarding';

Sentry.init({
  dsn: 'https://b5bbcf7b5de810396c397f7d44a5785e@o4507691834015744.ingest.de.sentry.io/4507708083142736',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        window.location.origin,

        environments.alpha.mlURL,
        environments.alpha.rubyURL,
        environments.alpha.nodeURL,

        environments.prod.mlURL,
        environments.prod.rubyURL,
        environments.prod.nodeURL,
      ],
      networkRequestHeaders: ['*'],
      networkResponseHeaders: ['*'],

      unmask: ['*'],
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',

    environments.alpha.mlURL,
    environments.alpha.rubyURL,
    environments.alpha.nodeURL,

    environments.prod.mlURL,
    environments.prod.rubyURL,
    environments.prod.nodeURL,
  ],
  // Session Replay
  replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? false
      : true,

  environment: appEnvironment.name,
});

Amplify.configure({
  Auth: {
    userPoolId: appEnvironment.cognitoUserPoolId,
    userPoolWebClientId: appEnvironment.cognitoUserPoolWebClientId,
    region: appEnvironment.cognitoRegion,
  },
});

Hotjar.init(appEnvironment.hotjarSideId, appEnvironment.hotjarVersion);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const LoggedOutRoot = () => {
  const mobile = useMobile();

  return mobile ? <LoggedOutV2Mobile /> : <LoggedOutV2 />;
};

const loggedOutRouter = createBrowserRouter([
  {
    path: '/',
    element: <LoggedOutRoot />,
    errorElement: <LoggedOutRoot />,
  },
]);

const LoggedInV2Root = () => {
  const mobile = useMobile();
  const dispatch = useAppDispatch();

  const hasRun = useRef(false);

  useEffect(() => {
    if (!hasRun.current) {
      hasRun.current = true;
      dispatch(onboardingActions.clearMessages());
    }
  }, [dispatch]);

  useEffect(() => {
    accessTokenHeaders().then((headers) => {
      const resolvedLanguage = i18next.resolvedLanguage;
      if (resolvedLanguage) {
        mlClient
          .post('Language', { language_code: resolvedLanguage }, { headers })
          .then(() => {
            dispatch(api.util.resetApiState());
          });
      }
    });
  }, [dispatch]);

  return mobile ? <LoggedInV2Mobile /> : <LoggedInV2 />;
};

const onboardingQuestionsRouter = createBrowserRouter([
  {
    path: '/',
    element: <LoggedInV2Root />,
    errorElement: <LoggedInV2Root />,
    index: true,
  },
]);

const Root = () => {
  const navigationState = useAppSelector(
    (state) => state.checkout.navigationState
  );
  const dispatch = useAppDispatch();

  const mobile = useMobile();

  const prevMobileRef = useRef(mobile);

  useEffect(() => {
    if (prevMobileRef.current !== mobile) {
      prevMobileRef.current = mobile;
      window.location.reload();
    }
  }, [mobile]);

  useEffect(() => {
    Auth.currentSession()
      .then(() => {
        dispatch(anayticsIdentifyUser());
        if (
          localStorage.getItem(NAVIGATION_STATE_KEY) === CONTINUE_HERE_SELECTED
        ) {
          dispatch(checkoutActions.setNavigationState('LOGGED_IN'));
        } else {
          dispatch(
            checkoutActions.setNavigationState('SHOW_SUCCESS_ONBOARDING')
          );
        }
      })
      .catch(() => {
        dispatch(checkoutActions.setNavigationState('LOGGED_OUT'));
      })
      .finally(() => {
        setTimeout(() => {
          document
            .querySelector('meta[name=viewport]')
            ?.setAttribute(
              'content',
              'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1'
            );
        }, 2000);
      });
  }, [dispatch]);

  useEffect(() => {
    const resolvedLanguage = i18next.resolvedLanguage;
    if (resolvedLanguage === 'ar') {
      document.getElementById('root')?.setAttribute('dir', 'rtl');
    }
  }, []);

  const view = useMemo(() => {
    switch (navigationState) {
      case 'LOGGED_OUT':
        return <RouterProvider router={loggedOutRouter} />;
      case 'SHOW_SUCCESS_ONBOARDING':
      case 'LOGGED_IN':
        return <RouterProvider router={onboardingQuestionsRouter} />;
      default:
        return null;
    }
  }, [navigationState]);

  return view;
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Root />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
