import {
  init as amplitudeInit,
  setUserId as amplitudeSetUserId,
  Identify,
  identify as amplitudeIdentify,
  track as amplitudeTrack,
} from '@amplitude/analytics-browser';
import { appEnvironment } from './environment';

type IdentificationProps = { id: number } & Record<
  string,
  string | number | boolean
>;

declare const _cio: {
  identify: (props: IdentificationProps) => void;

  track: (
    event: string,
    payload: Record<string, string | number | boolean>
  ) => void;
};

const customerIO = _cio;

const analyticsTracker = {
  init() {
    amplitudeInit(appEnvironment.amplitudeAPIKey, undefined, {
      minIdLength: 1,
    });
  },

  identify(props: IdentificationProps) {
    customerIO.identify(props);

    const amplityIdentity = new Identify();
    Object.keys(props).forEach((key) => {
      amplityIdentity.set(key, props[key]);
    });

    amplitudeSetUserId(props.id.toString());
    amplitudeIdentify(amplityIdentity);
  },

  track(
    event: string,
    payload: Record<string, string | number | boolean> = {}
  ) {
    amplitudeTrack(event, payload);
    customerIO.track(event, payload);
  },
};
analyticsTracker.init();

export default analyticsTracker;
