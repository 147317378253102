import { Stack, Switch, Typography } from '@mui/material';
import { useImperativeHandle, useMemo } from 'react';
import { forwardRef, useCallback } from 'react';
import { useEffect } from 'react';
import { ChangeEvent, useState } from 'react';
import analyticsTracker from '../../../common/analytics';
import {
  analyticsEvents,
  analyticsScreenNames,
} from '../../../common/analyticsEvents';
import { createStylesUtils } from '../../../common/common';
import { onboardingActions } from '../../../redux/onboarding';
import { answerQuestion } from '../../../redux/onboarding';
import {
  OnboardingContinueRef,
  OnboardingQuestion,
} from '../../../redux/onboardingTypes';
import { useAppDispatch } from '../../../redux/store';
import LargeInput from '../LargeInput';
import { largeInputStyles } from '../styles';
import { useQuestionNavigation } from '../../common/hooks';
import { useMobile } from '../../../common/hooks';
import { useTranslation } from 'react-i18next';

const WeightForm = forwardRef<OnboardingContinueRef, Props>(
  ({ question }, ref) => {
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      onContinuePressed: async () => {
        analyticsTracker.track(analyticsEvents.CLICK_CONFIRM_BUTTON, {
          screen: analyticsScreenNames.WEIGHT,
        });

        dispatch(
          answerQuestion({
            answers: [answer],
            question_id: question.question_id,
            imperial: !metric,
          })
        ).then(goNext);
      },
    }));

    useEffect(() => {
      analyticsTracker.track(analyticsEvents.VIEW_WEIGHT_SCREEN);
    }, []);

    const { goNext } = useQuestionNavigation();

    const [kg, setKg] = useState('');
    const [lbs, setLbs] = useState('');
    const [metric, setMetric] = useState(true);

    const dispatch = useAppDispatch();

    useEffect(() => {
      if (question.answer !== '') {
        if (!question.is_imperial) {
          setMetric(true);
          setKg(question.answer);
        } else {
          setMetric(false);
          setLbs(question.answer);
        }
      }
    }, [question.answer, question.is_imperial]);

    const answer = useMemo(() => {
      if (metric) {
        return kg;
      } else {
        return lbs;
      }
    }, [kg, lbs, metric]);

    useEffect(() => {
      let buttonEnabled = false;
      if (metric && kg.length > 0) {
        buttonEnabled = true;
      }
      if (!metric && lbs.length > 0) {
        buttonEnabled = true;
      }
      dispatch(onboardingActions.setButtonEnabled(buttonEnabled));
    }, [dispatch, kg.length, lbs.length, metric]);

    const onValueChanged = useCallback((setValue: (value: string) => void) => {
      return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        if (value === '' || isNaN(Number(value))) {
          setValue('');
        } else {
          setValue(value);
        }
      };
    }, []);

    const mobile = useMobile();

    const largeInputStyle = useMemo(() => {
      return mobile
        ? {
            ...largeInputStyles.countryInputWhiteBorder,
            width: undefined,

            '& .MuiInput-input': {
              fontSize: '24px',
              lineHeight: '24px',
              borderWidth: '1px',
              caretColor: 'white',
            },
          }
        : {};
    }, [mobile]);

    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        flex={mobile ? 1 : undefined}
      >
        {metric ? (
          <LargeInput
            placeholder={t('kgPlaceholder', { weight: '00' })}
            inputProps={{ inputMode: 'numeric' }}
            sx={{
              ...styles.input,
              ...largeInputStyle,
            }}
            value={kg}
            onChange={onValueChanged(setKg)}
            white={mobile}
          />
        ) : (
          <LargeInput
            placeholder={t('lbsPlaceholder', { weight: '00' })}
            inputProps={{ inputMode: 'numeric' }}
            sx={{
              ...styles.input,
              ...largeInputStyle,
            }}
            value={lbs}
            onChange={onValueChanged(setLbs)}
            white={mobile}
          />
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ marginTop: '20px', gap: '10px' }}
        >
          <Typography
            sx={[
              styles.switchLabel,
              mobile
                ? {
                    color: 'white',
                    fontSize: '12px',
                    lineHeight: '12px',
                    fontWeight: 500,
                  }
                : {},
            ]}
          >
            {t('Lbs')}
          </Typography>
          <Switch
            disableRipple={true}
            sx={{
              ...styles.switch,
              ...(mobile
                ? {
                    padding: '0px',
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track, .MuiSwitch-track':
                      {
                        backgroundColor: '#62656E',
                      },
                  }
                : {}),
            }}
            checked={metric}
            onChange={(_, checked) => setMetric(checked)}
          />
          <Typography
            sx={[
              styles.switchLabel,
              mobile
                ? {
                    color: 'white',
                    fontSize: '12px',
                    lineHeight: '12px',
                    fontWeight: 500,
                  }
                : {},
            ]}
          >
            {t('Kg')}
          </Typography>
        </Stack>
      </Stack>
    );
  }
);

export default WeightForm;

interface Props {
  question: OnboardingQuestion;
}

const styles = createStylesUtils({
  unit: {
    fontSize: '64px',
    lineHeight: '64px',
    color: '#292D32',
    fontWeight: '500',
  },

  input: {
    ...largeInputStyles.countryInput,
    width: '350px',
    '& .MuiInput-input': {
      fontSize: '64px',
      lineHeight: '64px',
      borderWidth: '1px',
    },
  },

  nonMetricInput: {
    ...largeInputStyles.countryInput,
    width: '150px',
    '& .MuiInput-input': {
      fontSize: '64px',
      lineHeight: '64px',
      borderWidth: '1px',
    },
  },

  switch: {
    '&.MuiSwitch-root': {
      height: '70px',
      width: '124px',
      boxSizing: 'border-box',
      border: '10px solid #FFFFFF',
      borderRadius: '36px',
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: 'white',
      width: '50px',
      height: '50px',
      border: '20px solid #262626',
      boxSizing: 'border-box',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track, .MuiSwitch-track':
      {
        backgroundColor: 'transparent',
      },
    '& .MuiSwitch-switchBase': {
      height: '50px',
      padding: '0px',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(49px)',
    },
  },

  switchLabel: {
    color: '#292D3266',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '600',
  },
});
