export const analyticsScreenNames = {
  CREATE_ACCOUNT: 'create account',
  PHONE_NUMBER_ENTRY: 'Phone number entry',
  PHONE_VERIFICATION: 'Phone verification',
  CARD_DETAILS: 'Card details',
  BILLING_ADDRESS: 'Billing address',
  DELIVERY_ADDRESS: 'Delivery address',
  SUMMARY: 'Summary',
  LAST_NAME: 'Last name',
  BIRTH_DATE: 'Birth date',
  GENDER: 'Gender',
  CITIZENSHIP: 'Citizenship',
  AVATAR: 'Avatar',
  SUCCESS: 'Success',
  HEIGHT: 'Height',
  WEIGHT: 'Weight',
  BMI: 'BMI',
  SMOKER_1: 'Smoker 1',
  SMOKER_2: 'Smoker 2',
  BACKPAIN: 'Backpain',
  ETHNICITY: 'Ethnicity',
  DIFFICULTIES: 'Difficulties',
  WIND_UP: 'Wind up',
  ENJOYMENT: 'Enjoyment',
  GOALS: 'Goals',
  GOALS_PROGRESS: 'Goals progress',

  QUESTION_TILE: 'Question Tile',
  QUESTION_SLIDER: 'Question Slider',
  QUESTION_SUGGESTION: 'Question Suggestion',
};

export const analyticsEvents = {
  VIEW_CREATE_ACCOUNT_SCREEN: 'View "create account" screen',
  CLICK_CONFIRM_BUTTON: 'click "Confirm" button',
  TICK_TC_BOX: 'tick TC box',
  VIEW_PHONE_NUMBER_ENTRY_SCREEN: 'View "Phone number entry" screen',
  CLICK_SEND_CODE_BUTTON: 'Click send code button',

  VIEW_PHONE_VERIFICATION_SCREEN: 'View Phone verification screen',
  CLICK_RESEND_CODE_BUTTON: 'Click "resend code" button',
  CLICK_VERIFY_BUTTON: 'Click "Verify" button',
  VIEW_CARD_DETAILS_SCREEN: 'View "Card details" screen',
  VIEW_BILLING_ADDRESS_SCREEN: 'View "Billing address" screen',
  ADDRESS_1_FIELD_ENTRY: 'Address 1 field entry',
  ADDRESS_2_FIELD_ENTRY: 'Address 2 field entry',
  NAME_ENTRY: 'Name entry',
  COUNTRY_PICKER_SELECTION: 'Country picker selection',
  VIEW_DELIVERY_ADDRESS_SCREEN: 'View "Delivery address" screen',
  VIEW_SUMMARY_SCREEN: 'View "Summary" screen',
  CLICK_PAY_AND_ACTIVATE_NOW_BUTTON: 'Click "Pay and activate now" button',
  CLICK_CONTINUE_IN_THE_APP_BUTTON: 'Click "Continue in the app" button',
  CLICK_CONTINUE_HERE_BUTTON: 'Click "Continue here" button',
  VIEW_LAST_NAME_SCREEN: 'View "Last name" screen',
  VIEW_BIRTH_DATE_SCREEN: 'View "Birth date" screen',
  VIEW_GENDER_SCREEN: 'View "Gender" screen',
  CLICK_GENDER_TILE: 'Click gender tile',
  VIEW_CITIZENSHIP_SCREEN: 'View "Citizenship" screen',
  VIEW_AVATAR_SCREEN: 'View "Avatar" screen',
  CHARACTER_SELECTION: 'Character selection',
  SKIN_COLOR: 'Skin color',
  EMOTION_SELECTION: 'Emotion selection',
  VIEW_SUCCESS_SCREEN: 'View "Success" screen',
  VIEW_HEIGHT_SCREEN: 'View "Height" screen',
  VIEW_WEIGHT_SCREEN: 'View "Weight" screen',
  VIEW_BMI_SCREEN: 'View "BMI" screen',
  VIEW_SMOKER_1_SCREEN: 'View "Smoker 1" screen',
  CLICK_SMOKER_1_TILE: 'Click Smoker 1 tile',
  VIEW_SMOKER_2_SCREEN: 'View "Smoker 2" screen',
  CLICK_SMOKER_2_TILE: 'Click Smoker 2 tile',
  VIEW_BACKPAIN_SCREEN: 'View "Backpain" screen',
  PICKER_POSITION: 'picker position',
  VIEW_ETHNICITY_SCREEN: 'View "Ethnicity" screen',
  CLICK_ETHNICITY_TILE: 'Click Ethnicity tile',
  VIEW_DIFFICULTIES_SCREEN: 'View "Difficulties" screen',
  VIEW_WIND_UP_SCREEN: 'View "Wind up" screen',
  VIEW_ENJOYMENT_SCREEN: 'View "Enjoyment" screen',
  VIEW_LQI_CARDS_SCREEN: 'View "LQI cards" screen',
  VIEW_GOALS_SCREEN: 'View "Goals" screen',
  CLICK_GOAL_TILE: 'Click Goal tile',
  VIEW_GOALS_PROGRESS_SCREEN: 'View "Goals progress" screen',
  VIEW_COMPLETED_SCREEN: 'View "Completed" screen',

  CLICK_QUESTION_TILE: 'Click Question Tile',
  CLICK_QUESTION_SLIDER: 'Click Question Slider',
  VIEW_QUESTION_TILE_SCREEN: 'View Question Tile Screen',
  VIEW_QUESTION_SLIDER_SCREEN: 'VIew Question Slider Screen',
};
