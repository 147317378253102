import { Stack, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../redux/store';
import {
  CHECKOUT_DATA_KEY,
  CheckoutState,
  checkoutActions,
} from '../../redux/checkout';
import { checkout } from '../../redux/checkoutActions';
import { useTranslation } from 'react-i18next';

export default function CheckoutForm() {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const sentRef = useRef(false);

  useEffect(() => {
    if (!sentRef.current) {
      sentRef.current = true;

      const checkoutState = JSON.parse(
        localStorage.getItem(CHECKOUT_DATA_KEY) || '{}'
      ) as CheckoutState;

      dispatch(checkoutActions.setCheckoutState(checkoutState));
      const paymentIntent = searchParams.get('payment_intent') || undefined;

      dispatch(
        checkout(
          paymentIntent === 'no_intent_required' ? undefined : paymentIntent
        )
      );
    }
  }, [dispatch, searchParams]);

  return (
    <Stack
      className="w-full h-full"
      justifyContent="center"
      alignItems="center"
    >
      <Typography className="text-[40px]">{t('pleaseWait')}</Typography>
    </Stack>
  );
}
