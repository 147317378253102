import { Box, Stack } from '@mui/material';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { images } from '../../../assets/images';
import analyticsTracker from '../../../common/analytics';
import {
  analyticsEvents,
  analyticsScreenNames,
} from '../../../common/analyticsEvents';
import { createStylesUtils } from '../../../common/common';
import { useMobile } from '../../../common/hooks';
import PaginationView from '../../../common/PaginationView';
import { onboardingActions } from '../../../redux/onboarding';
import { answerQuestion } from '../../../redux/onboarding';
import {
  OnboardingContinueRef,
  OnboardingQuestion,
} from '../../../redux/onboardingTypes';
import { useAppDispatch } from '../../../redux/store';
import QuestionCell from './QuestionCell';
import { useQuestionNavigation } from '../../common/hooks';
import QuestionTooltip from '../../../components_v2/questions/QuestionTooltip';

const QuestionForm = forwardRef<OnboardingContinueRef, Props>((props, ref) => {
  useImperativeHandle(ref, () => ({
    onContinuePressed: async () => {
      analyticsTracker.track(
        analyticsEvents.CLICK_CONFIRM_BUTTON,
        analyticsScreenPayload
      );

      dispatch(
        answerQuestion({
          answers: [selected],
          question_id: question.question_id,
        })
      ).then(goNext);
    },
  }));

  const { goNext } = useQuestionNavigation();

  const { question } = props;

  const analyticsScreenPayload = useMemo(() => {
    return {
      screen: analyticsScreenNames.QUESTION_TILE,
      question: question.question_text,
    };
  }, [question.question_text]);

  useEffect(() => {
    analyticsTracker.track(
      analyticsEvents.VIEW_QUESTION_TILE_SCREEN,
      analyticsScreenPayload
    );
  }, [analyticsScreenPayload]);

  const mobile = useMobile();

  const pageSize = useMemo(() => {
    return mobile ? 4 : 6;
  }, [mobile]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(onboardingActions.setButtonEnabled(true));
  }, [dispatch]);

  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(question.answers[0].id);
  const selectedRef = useRef<string>();

  useEffect(() => {
    if (question.answer !== '') {
      setSelected(question.answer);
    } else {
      setSelected(question.answers[0].id);
    }
  }, [question]);

  useEffect(() => {
    if (selectedRef.current !== selected) {
      selectedRef.current = selected;

      analyticsTracker.track(analyticsEvents.CLICK_QUESTION_TILE, {
        ...analyticsScreenPayload,
        selectedQuestionId: selected,
        selectedQuestionText:
          question.answers.find((item) => item.id === selected)?.text ?? '',
      });
    }
  }, [analyticsScreenPayload, question.answers, selected]);

  const showArrows = useMemo(() => {
    if (mobile) {
      return false;
    }
    return question.answers.length > pageSize;
  }, [mobile, pageSize, question.answers.length]);

  const topRow = useMemo(() => {
    const { answers } = question;
    return answers.slice(page * pageSize, page * pageSize + pageSize / 2);
  }, [pageSize, page, question]);

  const bottomRow = useMemo(() => {
    const { answers } = question;
    return answers.slice(
      page * pageSize + pageSize / 2,
      page * pageSize + pageSize
    );
  }, [pageSize, page, question]);

  const totalPages = useMemo(() => {
    const { answers } = question;
    return Math.ceil(answers.length / pageSize) - 1;
  }, [pageSize, question]);

  const goLeft = useCallback(() => {
    if (page > 0) {
      setPage(page - 1);
    }
  }, [page]);

  const goRight = useCallback(() => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  }, [page, totalPages]);

  return (
    <Stack
      gap="40px"
      flex={1}
      alignItems="center"
      className={mobile ? 'mt-[20px]' : ''}
    >
      <Stack direction="column" flex={1}>
        <Stack
          direction="row"
          spacing="20px"
          flex={1}
          sx={mobile ? {} : { marginBottom: '20px' }}
        >
          {showArrows && (
            <Box
              component="img"
              sx={styles.arrow}
              src={images.pagination.arrowLeft}
              onClick={() => {
                if (page > 0) {
                  setPage(page - 1);
                }
              }}
            />
          )}

          <Stack
            alignItems="center"
            justifyContent="center"
            direction="column"
            spacing={mobile ? '8px' : '20px'}
            className="flex-1"
          >
            <Stack
              direction="row"
              justifyContent="center"
              columnGap={mobile ? '8px' : '30px'}
              rowGap={mobile ? '8px' : '20px'}
              flexWrap="wrap"
              sx={{
                width: '100%',
              }}
            >
              {topRow.map((item) => (
                <QuestionCell
                  key={item.id}
                  answer={item}
                  selected={item.id === selected}
                  onSelected={(item) => setSelected(item.id)}
                />
              ))}
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              columnGap={mobile ? '8px' : '30px'}
              rowGap={mobile ? '8px' : '20px'}
              flexWrap="wrap"
              sx={{
                width: '100%',
              }}
            >
              {bottomRow.map((item) => (
                <QuestionCell
                  key={item.id}
                  answer={item}
                  selected={item.id === selected}
                  onSelected={(item) => setSelected(item.id)}
                />
              ))}
            </Stack>
          </Stack>

          {showArrows && (
            <Box
              component="img"
              sx={styles.arrow}
              src={images.pagination.arrowRight}
              onClick={() => {
                if (page < totalPages) {
                  setPage(page + 1);
                }
              }}
            />
          )}
        </Stack>

        {question.answers.length > pageSize && (
          <PaginationView
            onLeftSelected={goLeft}
            onRightSelected={goRight}
            onPageSelected={setPage}
            page={page}
            totalPages={totalPages}
            hideArrows={!mobile}
            white={mobile}
          />
        )}
      </Stack>

      {!mobile && question.insight && question.insight !== '' && (
        <QuestionTooltip question={question} />
      )}
    </Stack>
  );
});

const styles = createStylesUtils({
  arrow: {
    width: '36px',
    height: '36px',
    alignSelf: 'center',

    ':hover': {
      cursor: 'pointer',
      opacity: 0.7,
    },
  },
});

interface Props {
  question: OnboardingQuestion;
}

export default QuestionForm;
