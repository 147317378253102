import { Stack, Typography } from '@mui/material';
import QRCode from '../../common/QRCode';
import { useMemo, useRef, useState } from 'react';
import { useAppSelector } from '../../redux/store';
import { useTranslation } from 'react-i18next';

export default function BottomQR() {
  const { t } = useTranslation();

  const [hovered, setHovered] = useState(false);

  const questions = useAppSelector((state) => state.onboarding.questions);
  const qrPosition = useAppSelector((state) => state.onboarding.qrPosition);
  const ref = useRef<HTMLDivElement>();

  const finished = useMemo(() => {
    if (questions.length === 0) {
      return false;
    }

    return questions[questions.length - 1].finished;
  }, [questions]);

  const rtl = useMemo(() => {
    return document.getElementById('root')?.getAttribute('dir') === 'rtl';
  }, []);

  return (
    <Stack
      className="flex-row items-end absolute bottom-[20px] end-0 gap-[20px] hover:cursor-pointer"
      onMouseEnter={() => {
        if (!finished) {
          setHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (!finished) {
          setHovered(false);
        }
      }}
    >
      {!finished && (
        <Typography
          className={`text-[14px] font-semibold ${
            rtl ? 'translate-x-[-100px]' : 'translate-x-[100px]'
          } ${finished ? 'text-white' : 'text-[#0ABAB5]'}`}
        >
          {t('appIsReadyToInstall')}
        </Typography>
      )}

      <Stack
        ref={ref}
        sx={
          qrPosition
            ? {
                transform: `translate(-15%, ${
                  -window.innerHeight + qrPosition.y + 90
                }px) scale(2) !important`,
                transition: 'all 1s !important',
              }
            : {}
        }
        className={`justify-center items-center w-[180px] h-[180px] rounded-full bg-white transition ${
          hovered
            ? `${
                rtl ? 'translate-x-[25%]' : 'translate-x-[-25%]'
              } translate-y-[-25%] scale-[1.5]`
            : `translate-y-[50%] ${
                rtl ? 'translate-x-[-50%]' : 'translate-x-[50%]'
              }`
        }`}
      >
        <QRCode size={100} />
      </Stack>
    </Stack>
  );
}
