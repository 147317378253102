import { Box, Stack, Typography } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import analyticsTracker from '../../../common/analytics';
import {
  analyticsEvents,
  analyticsScreenNames,
} from '../../../common/analyticsEvents';
import { createStylesUtils } from '../../../common/common';
import { ASSETS_URL } from '../../../common/environment';
import { useMobile } from '../../../common/hooks';
import { onboardingActions } from '../../../redux/onboarding';
import { answerQuestion } from '../../../redux/onboarding';
import {
  OnboardingAnswer,
  OnboardingContinueRef,
  OnboardingQuestion,
} from '../../../redux/onboardingTypes';
import { useAppDispatch } from '../../../redux/store';
import { useQuestionNavigation } from '../../common/hooks';
import { useTranslation } from 'react-i18next';

const GoalForm = forwardRef<OnboardingContinueRef, Props>((props, ref) => {
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    onContinuePressed: async () => {
      dispatch(
        answerQuestion({
          answers: [selected],
          question_id: question.question_id,
        })
      ).then(goNext);
    },
  }));

  const { goNext } = useQuestionNavigation();

  const dispatch = useAppDispatch();
  const { question } = props;

  useEffect(() => {
    analyticsTracker.track(analyticsEvents.VIEW_GOALS_SCREEN);
  }, []);

  useEffect(() => {
    dispatch(onboardingActions.setButtonEnabled(true));
  }, [dispatch]);

  const [selected, setSelected] = useState(question.answers[0].id);

  useEffect(() => {
    if (question.answer !== '') {
      setSelected(question.answer);
    }
  }, [question.answer]);

  useEffect(() => {
    analyticsTracker.track(analyticsEvents.CLICK_GOAL_TILE, {
      screen: analyticsScreenNames.GOALS,
      goalId: selected,
    });
  }, [selected]);

  const mobile = useMobile();

  return (
    <Stack direction="column" alignItems="center">
      <Stack
        direction={mobile ? 'column' : 'row'}
        spacing={mobile ? '10px' : '30px'}
        rowGap={!mobile ? '20px' : undefined}
        sx={mobile ? { marginTop: ' 20px' } : {}}
        flexWrap={!mobile ? 'wrap' : undefined}
        justifyContent={!mobile ? 'center' : undefined}
      >
        {question.answers.map((item) => (
          <GoalCell
            answer={item}
            selected={selected === item.id}
            onSelected={setSelected}
          />
        ))}
      </Stack>

      <Typography sx={styles.suggestedGoals}>
        {t('theseAreSuggestedGoal')}
      </Typography>
    </Stack>
  );
});

export default GoalForm;

const GoalCell = ({
  answer,
  selected,
  onSelected,
}: {
  answer: OnboardingAnswer;
  selected: boolean;
  onSelected: (id: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      sx={[styles.goalBackground, selected ? styles.selected : {}]}
      onClick={() => onSelected(answer.id)}
    >
      <Box
        component="img"
        sx={styles.image}
        src={`${ASSETS_URL}/uploads/goals_v5/${answer.imgUrl_v2}`}
      />

      <Typography className="text-text" sx={[styles.goalName]}>
        {answer.text}
      </Typography>
      <Typography sx={styles.suggested}>{t('Suggested')}</Typography>
    </Stack>
  );
};

const styles = createStylesUtils({
  goalBackground: {
    width: '250px',
    height: '160px',

    borderRadius: '24px',
    padding: '20px',
    position: 'relative',
    boxSizing: 'border-box',

    backgroundColor: '#fcfdfd',
    border: '4px solid transparent',

    ':hover': {
      cursor: 'pointer',
    },
  },

  image: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100px',
    height: '100px',

    borderRadius: '24px',
    objectFit: 'cover',
  },

  goalName: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '600',
    height: '60px',
  },

  suggested: {
    backgroundColor: '#eeefef',
    borderRadius: '16px',
    padding: ' 0px 9px 0px 8px',
    color: '#262626',
    fontSize: '10px',
    lineHeight: '22px',
    marginTop: '10px',
  },

  selected: {
    border: '4px solid #0ABAB5',
  },

  suggestedGoals: {
    marginTop: '30px',
    textAlign: 'center',
    maxWidth: '330px',
    color: '#292D3266',
    fontSize: '14px',
    lineHeight: '22px',
  },
});

interface Props {
  question: OnboardingQuestion;
}
