import { Stack, Switch, Typography } from '@mui/material';
import { useImperativeHandle, useMemo } from 'react';
import { forwardRef, useCallback, useRef } from 'react';
import { useEffect } from 'react';
import { ChangeEvent, useState } from 'react';
import analyticsTracker from '../../../common/analytics';
import {
  analyticsEvents,
  analyticsScreenNames,
} from '../../../common/analyticsEvents';
import { createStylesUtils } from '../../../common/common';
import { onboardingActions } from '../../../redux/onboarding';
import { answerQuestion } from '../../../redux/onboarding';
import {
  OnboardingContinueRef,
  OnboardingQuestion,
} from '../../../redux/onboardingTypes';
import { useAppDispatch } from '../../../redux/store';
import LargeInput from '../LargeInput';

import { largeInputStyles } from '../styles';
import { useQuestionNavigation } from '../../common/hooks';
import { useMobile } from '../../../common/hooks';
import { useTranslation } from 'react-i18next';

const HeightForm = forwardRef<OnboardingContinueRef, Props>(
  ({ question }, ref) => {
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      onContinuePressed: async () => {
        analyticsTracker.track(analyticsEvents.CLICK_CONFIRM_BUTTON, {
          screen: analyticsScreenNames.HEIGHT,
        });

        dispatch(
          answerQuestion({
            answers: [answer],
            question_id: question.question_id,
            imperial: !metric,
          })
        ).then(goNext);
      },
    }));

    useEffect(() => {
      analyticsTracker.track(analyticsEvents.VIEW_HEIGHT_SCREEN);
    }, []);

    const { goNext } = useQuestionNavigation();

    const [cm, setCm] = useState('');
    const [feet, setFeet] = useState('');
    const [inches, setInches] = useState('');
    const [metric, setMetric] = useState(true);

    const inchesRef = useRef<HTMLInputElement>();

    const dispatch = useAppDispatch();

    useEffect(() => {
      if (question.answer !== '') {
        if (question.is_imperial) {
          setMetric(false);

          const [feet, inches] = question.answer.replace("''", '').split("'");
          setFeet(feet);
          setInches(inches);
        } else {
          setMetric(true);
          setCm(question.answer);
        }
      }
    }, [question.answer, question.is_imperial]);

    const answer = useMemo(() => {
      if (metric) {
        return cm;
      } else {
        return `${feet}'${inches}''`;
      }
    }, [cm, feet, inches, metric]);

    useEffect(() => {
      let buttonEnabled = false;
      if (metric && cm.length > 0) {
        buttonEnabled = true;
      }
      if (!metric && feet.length > 0 && inches.length > 0) {
        buttonEnabled = true;
      }
      dispatch(onboardingActions.setButtonEnabled(buttonEnabled));
    }, [cm.length, dispatch, feet.length, inches.length, metric]);

    const onValueChanged = useCallback(
      (setValue: (value: string) => void, callback?: () => void) => {
        return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          const value = e.target.value;
          if (value === '' || isNaN(Number(value))) {
            setValue('');
          } else {
            setValue(value);
            if (callback !== undefined) {
              setTimeout(callback, 50);
            }
          }
        };
      },
      []
    );

    const mobile = useMobile();

    const largeInputStyle = useMemo(() => {
      return mobile
        ? {
            ...largeInputStyles.countryInputWhiteBorder,
            width: undefined,

            '& .MuiInput-input': {
              fontSize: '24px',
              lineHeight: '24px',
              borderWidth: '1px',
              caretColor: 'white',
            },
          }
        : {};
    }, [mobile]);

    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        flex={mobile ? 1 : undefined}
      >
        <Typography
          className={`mb-[20px] font-semibold ${
            mobile ? 'text-white' : 'text-text text-[24px]'
          }`}
        >
          {t('height')}
        </Typography>

        {metric ? (
          <LargeInput
            placeholder={t('cmPlaceholder', { height: '00' })}
            inputProps={{ inputMode: 'numeric' }}
            sx={{
              ...styles.input,
              ...largeInputStyle,
            }}
            value={cm}
            onChange={onValueChanged(setCm)}
            white={mobile}
          />
        ) : (
          <Stack direction="row" alignItems="center">
            <LargeInput
              placeholder="0"
              inputProps={{ inputMode: 'numeric' }}
              sx={{
                ...styles.nonMetricInput,
                ...largeInputStyle,
                ...(mobile ? { width: '100px' } : {}),
              }}
              value={feet}
              white={mobile}
              onChange={onValueChanged(setFeet, () => {
                if (inchesRef.current) {
                  inchesRef.current.focus();
                }
              })}
            />
            <Typography
              sx={[
                styles.unit,
                mobile
                  ? { color: 'white', fontSize: '24px', lineHeight: '24px' }
                  : {},
              ]}
            >
              '
            </Typography>

            <LargeInput
              placeholder="00"
              inputProps={{ inputMode: 'numeric' }}
              sx={{
                ...styles.nonMetricInput,
                ...largeInputStyle,
                ...(mobile ? { width: '100px' } : {}),
              }}
              value={inches}
              onChange={onValueChanged(setInches)}
              white={mobile}
              inputRef={inchesRef}
            />
            <Typography
              sx={[
                styles.unit,
                mobile
                  ? { color: 'white', fontSize: '24px', lineHeight: '24px' }
                  : {},
              ]}
            >
              ''
            </Typography>
          </Stack>
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ marginTop: '20px', gap: '10px' }}
        >
          <Typography
            sx={[styles.switchLabel, mobile ? { color: 'white' } : {}]}
          >
            {t('feet')}
          </Typography>

          <Switch
            disableRipple={true}
            sx={{
              ...styles.switch,
              ...(mobile
                ? {
                    padding: '0px',
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track, .MuiSwitch-track':
                      {
                        backgroundColor: '#62656E',
                      },
                  }
                : {}),
            }}
            checked={metric}
            onChange={(_, checked) => setMetric(checked)}
          />
          <Typography
            sx={[styles.switchLabel, mobile ? { color: 'white' } : {}]}
          >
            {t('cm')}
          </Typography>
        </Stack>
      </Stack>
    );
  }
);

export default HeightForm;

interface Props {
  question: OnboardingQuestion;
}

const styles = createStylesUtils({
  unit: {
    fontSize: '64px',
    lineHeight: '64px',
    color: '#292D32',
    fontWeight: '500',
  },

  input: {
    ...largeInputStyles.countryInput,
    width: '350px',
    '& .MuiInput-input': {
      fontSize: '64px',
      lineHeight: '64px',
      borderWidth: '1px',
    },
  },

  nonMetricInput: {
    ...largeInputStyles.countryInput,
    width: '150px',

    '& .MuiInput-input': {
      fontSize: '64px',
      lineHeight: '64px',
      borderWidth: '1px',
    },
  },

  switch: {
    '&.MuiSwitch-root': {
      height: '70px',
      width: '124px',
      boxSizing: 'border-box',
      border: '10px solid #FFFFFF',
      borderRadius: '36px',
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: 'white',
      width: '50px',
      height: '50px',
      border: '20px solid #262626',
      boxSizing: 'border-box',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track, .MuiSwitch-track':
      {
        backgroundColor: 'transparent',
      },
    '& .MuiSwitch-switchBase': {
      height: '50px',
      padding: '0px',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(49px)',
    },
  },

  switchLabel: {
    color: '#292D3266',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '600',
  },
});
