import { Box, Input, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { images } from '../../assets/images';
import { useAppDispatch } from '../../redux/store';
import { onboardingActions } from '../../redux/onboarding';
import { useTranslation } from 'react-i18next';

export default function GridFormSelection(props: Props) {
  const { t } = useTranslation();
  const { selection, onRemoved, onAdded, onCustomAnswerEntered } = props;

  const [customAnswer, setCustomAnswer] = useState('');

  const ref = useRef<HTMLDivElement>();
  const prevSelection = useRef(selection);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selection.length > prevSelection.current.length && ref.current) {
      ref.current.scrollTo({ left: ref.current.getBoundingClientRect().width });
    }

    prevSelection.current = selection;
  }, [selection]);

  const hasInvalidAnswers = useMemo(() => {
    return selection.find((item) => !item.valid) !== undefined;
  }, [selection]);

  useEffect(() => {
    dispatch(
      onboardingActions.setButtonEnabled(
        selection.length > 0 && !hasInvalidAnswers
      )
    );
  }, [dispatch, hasInvalidAnswers, selection.length]);

  const className = useMemo(() => {
    if (selection.length === 0) {
      if (customAnswer.length === 0) {
        return 'border-transparent';
      } else {
        return 'border-text';
      }
    } else {
      return hasInvalidAnswers ? 'border-[#E84D8A]' : 'border-[#0ABAB5]';
    }
  }, [customAnswer.length, hasInvalidAnswers, selection.length]);

  const selectedItem = useCallback(
    (answer: SuggestionAnswer) => {
      const backgroundClassName = answer.valid
        ? 'bg-[#0ABAB533]'
        : 'bg-[#E84D8A33]';
      const textClassName = answer.valid ? 'text-[#0ABAB5]' : 'text-[#E84D8A]';

      return (
        <Stack
          direction="row"
          alignItems="center"
          className={`py-1 px-4 rounded-[36px] ${backgroundClassName}`}
          flexShrink={0}
          key={answer.text}
        >
          <Typography className={`font-medium text-[14px] ${textClassName}`}>
            {answer.text}
          </Typography>

          <Box
            component="img"
            src={images.v2.onboarding.gridSelectionRemove}
            className="hover:cursor-pointer"
            onClick={() => onRemoved(answer)}
          />
        </Stack>
      );
    },
    [onRemoved]
  );

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        alignSelf="center"
        gap="4px"
        className={`p-4 rounded-[36px] border-[2px] bg-white min-h-[62px] max-w-[60%] min-w-[60%] overflow-x-scroll ${className}`}
        ref={ref}
        component="div"
        sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
      >
        {selection.map((item) => selectedItem(item))}

        <Input
          disableUnderline={true}
          className="font-medium min-w-[40px]"
          onChange={(e) => setCustomAnswer(e.target.value)}
          value={customAnswer}
          placeholder={selection.length === 0 ? t('typeInYourOwnAnswer') : ''}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onCustomAnswerEntered(customAnswer).then((valid) =>
                onAdded({ text: customAnswer, valid })
              );
              setCustomAnswer('');
            }
          }}
        />
      </Stack>

      <Typography
        className={`text-[10px] font-medium text-[#A09FA3] self-center mt-[10px] ${
          hasInvalidAnswers ? 'opacity-100' : 'opacity-0'
        }`}
      >
        {t('sorryButItDoesntLook')}
      </Typography>
    </Stack>
  );
}

interface Props {
  selection: SuggestionAnswer[];

  onRemoved: (answer: SuggestionAnswer) => void;
  onAdded: (answer: SuggestionAnswer) => void;

  onCustomAnswerEntered: (answer: string) => Promise<boolean>;
}

export interface SuggestionAnswer {
  id?: string;
  text: string;
  valid: boolean;
}
