import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { AxiosError } from 'axios';
import { nodeClient, rubyClient } from '../common/environment';
import { checkoutActions } from './checkout';
import { ShoppingCart, ShoppingCartV2 } from './checkoutTypes';
import { accessTokenHeaders, anayticsIdentifyUser } from './onboardingActions';
import { RootState } from './store';

export const addDiscountCode = createAsyncThunk(
  'checkout/addDiscountCode',
  async (coupon: string) => {
    const { data } = await rubyClient.post<ShoppingCart>(
      'shopping_cart/add_discount',
      {
        coupons: [coupon],
      }
    );

    return data;
  }
);

export const addDiscountCodeV2 = createAsyncThunk(
  'checkout/addDiscountCodeV2',
  async (coupon: string) => {
    const { data } = await nodeClient.post<ShoppingCartV2>(
      'checkout/addDiscountCode',
      {
        coupon,
      }
    );

    return data;
  }
);

export const createVerification = createAsyncThunk(
  'checkout/createVerification',
  async (
    { phoneNumber, token }: { phoneNumber: string; token: string },
    { rejectWithValue }
  ) => {
    try {
      const {
        data: { id },
      } = await nodeClient.post<{ id: number }>(
        'phone/createVerificationV2',
        {
          phoneNumber,
          token,
          platform: 'web',
        },
        { headers: await accessTokenHeaders() }
      );

      return { verificationId: id, phoneNumber };
    } catch (e) {
      const axiosError = e as AxiosError<{ error: string }>;
      if (axiosError.response?.data.error) {
        return rejectWithValue(axiosError.response?.data.error);
      } else {
        return rejectWithValue('Could not send code');
      }
    }
  }
);

export const resendCode = createAsyncThunk(
  'checkout/resendCode',
  async ({
    verificationId,
    token,
  }: {
    verificationId: number;
    token: string;
  }) => {
    await nodeClient.post(
      'phone/resendCodeV2',
      {
        id: verificationId,
        token,
        platform: 'web',
      },
      { headers: await accessTokenHeaders() }
    );
    return {};
  }
);

export const verifyCode = createAsyncThunk(
  'checkout/verifyCode',
  async ({
    verificationId,
    code,
  }: {
    verificationId: number;
    code: string;
  }) => {
    const {
      data: { success },
    } = await nodeClient.post<{ success: boolean }>(
      'phone/verify',
      {
        id: verificationId,
        code,
      },
      { headers: await accessTokenHeaders() }
    );
    return success;
  }
);

export const checkout = createAsyncThunk<void, string | undefined>(
  'checkout/checkout',
  async (paymentIntentId, { dispatch, getState }) => {
    const state = getState() as RootState;
    const personalData = state.checkout.personalData;

    const clientData = {
      email: personalData.email.toLowerCase(),
      first_name: personalData.name,
      last_name: personalData.lastName,
      password: personalData.password,
      gender: personalData.gender,
      birth_date: personalData.birthDate,
    };

    const billingAddress = {
      name: 'Billing Address',
      country: state.checkout.country.code,
      save: false,
    };

    let deliveryAddress: Record<string, unknown> = {
      save: false,
    };
    if (state.checkout.deliveryAddress) {
      deliveryAddress = {
        name: 'Delivery Address',
        address1: state.checkout.deliveryAddress.addressLine1,
        address2: state.checkout.deliveryAddress.addressLine2,
        city: state.checkout.deliveryAddress.city,
        postcode: state.checkout.deliveryAddress.postCode,
        country: state.checkout.country.code,
        save: false,
      };
    }

    const requestBody: Record<string, unknown> = {
      coupon: state.checkout.coupon,
      clientData,
      billingAddress,
      deliveryAddress,
      paymentIntentId,
    };

    await nodeClient.post(
      `checkout/${state.checkout.cartV2!.id}/checkoutV2`,
      requestBody
    );

    await Auth.signIn(personalData.email, personalData.password).then(() => {
      dispatch(checkoutActions.setNavigationState('SHOW_SUCCESS_ONBOARDING'));
      dispatch(anayticsIdentifyUser());
    });
  }
);
