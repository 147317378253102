import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import analyticsTracker from '../common/analytics';
import { nodeClient } from '../common/environment';
import * as Sentry from '@sentry/react';
import i18next from '../i18n';

const getAccessToken = async () => {
  return (await Auth.currentSession()).getAccessToken().getJwtToken();
};

export const accessTokenHeaders = async (
  extraHeaders: Record<string, string | number | boolean> = {}
) => {
  return {
    'Access-Token': await getAccessToken(),
    ...extraHeaders,
  };
};

const apiHeaders = async (
  extraHeaders: Record<string, string | number> = {}
) => ({
  'Access-Token': await getAccessToken(),
  appVersion: '4.1.2',
  ...extraHeaders,
});

export const mlAPIheaders = async (
  extraHeaders: Record<string, string | number | boolean> = {}
) => {
  return {
    ...(await apiHeaders()),
    timeStamp: new Date().getDate(),
    appVersion: '4.1.1',
    ...extraHeaders,
  };
};

export const anayticsIdentifyUser = createAsyncThunk(
  'analytics/identify',
  async (_) => {
    const {
      data: { email, name, surname, id_u, code },
    } = await nodeClient.get<{
      email: string;
      name: string;
      surname: string;
      id_u: number;
      code: string | null;
    }>('users/profile', {
      headers: await mlAPIheaders(),
    });
    const resolvedLanguage = i18next.resolvedLanguage;

    analyticsTracker.identify({
      id: id_u,
      email,
      FirstName: name,
      LastName: surname,
      Email: email,
      code: code || '',
      ...(resolvedLanguage ? { appLanguage: resolvedLanguage } : {}),
    });

    Sentry.setUser({
      id: id_u,
      email: email.toLowerCase(),
      name,
      lastName: surname,
    });
  }
);
