import { Stack, Box } from '@mui/material';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { images } from '../assets/images';

export default function PaginationView(props: Props) {
  const {
    onLeftSelected: onLeftClicked,
    onRightSelected: onRightClicked,
    onPageSelected,
    page,
    totalPages,
    hideArrows,
    white,
  } = props;

  const [minWidth, setMinWidth] = useState(-1);

  const onStackLoaded = useCallback(
    (stack: HTMLDivElement | null) => {
      if (stack && minWidth < 0) {
        setMinWidth(stack.clientWidth);
      }
    },
    [minWidth]
  );

  const views = useMemo(() => {
    const result: ReactNode[] = [];
    for (let i = 0; i < page; i++) {
      result.push(
        <Box
          key={`page${i}`}
          component="img"
          src={white ? images.pagination.bulletWhite : images.pagination.bullet}
          sx={{
            ':hover': { cursor: 'pointer' },
          }}
          onClick={() => onPageSelected(i)}
        />
      );
    }
    result.push(
      <Box
        key={`page${page}`}
        component="img"
        src={white ? images.pagination.lineWhite : images.pagination.line}
      />
    );
    for (let i = page + 1; i <= totalPages; i++) {
      result.push(
        <Box
          key={`page${i}`}
          component="img"
          src={white ? images.pagination.bulletWhite : images.pagination.bullet}
          sx={{
            ':hover': { cursor: 'pointer' },
          }}
          onClick={() => onPageSelected(i)}
        />
      );
    }

    return result;
  }, [onPageSelected, page, totalPages, white]);

  return (
    <Stack
      direction="row"
      alignSelf="stretch"
      justifyContent="space-between"
      alignItems="center"
      ref={onStackLoaded}
      sx={{
        marginTop: '16px',
        minWidth: `${minWidth}px`,
        '& .arrow': {
          width: '24px',
          height: '24px',
          ':hover': {
            cursor: 'pointer',
            opacity: 0.7,
          },
        },
      }}
    >
      {hideArrows ? (
        <Box />
      ) : (
        <img
          className="arrow"
          src={
            white
              ? images.pagination.arrowLeftWhite
              : images.pagination.arrowLeft
          }
          onClick={onLeftClicked}
        />
      )}

      <Stack
        direction="row"
        sx={{
          '& *': { padding: '0px 4px' },
          '& *:first-of-type': { paddingLeft: '0px' },
          '& *:last-child': { paddingRight: '0px' },
        }}
      >
        {views}
      </Stack>

      {hideArrows ? (
        <Box />
      ) : (
        <img
          className="arrow"
          src={
            white
              ? images.pagination.arrowRightWhite
              : images.pagination.arrowRight
          }
          onClick={onRightClicked}
        />
      )}
    </Stack>
  );
}

interface Props {
  hideArrows?: boolean;
  white?: boolean;
  page: number;
  totalPages: number;

  onLeftSelected: () => void;
  onRightSelected: () => void;
  onPageSelected: (page: number) => void;
}
