import { createStylesUtils } from '../common/common';

export const formStyles = createStylesUtils({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  formContainer: {
    width: 'calc(100% - 20px)',
    maxWidth: '488px',
    alignItems: 'center',
  },
});
