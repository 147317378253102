import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { onboardingActions } from './redux/onboarding';
import { OnboardingV2Step } from './redux/onboardingTypes';
import { useAppDispatch } from './redux/store';

export const useAppTransitions = (
  leftPanelImageRef: RefObject<HTMLImageElement>,
  onboarding: boolean
) => {
  const dispatch = useAppDispatch();
  const prevTranslateX = useRef(0);
  const hasRun = useRef(false);

  const [leftPanelTranslationX, setLeftPanelTranslationX] = useState(0);

  const translation = useCallback((image: HTMLImageElement) => {
    const rtl = document.getElementById('root')?.getAttribute('dir') === 'rtl';

    const centerX =
      image.getBoundingClientRect().width / 2 +
      image.getBoundingClientRect().left;
    const translateX = rtl ? window.innerWidth - centerX : -centerX;

    return translateX;
  }, []);

  const animatedRightPanelInOnboarding = useCallback(() => {
    if (leftPanelImageRef.current) {
      const translateX = translation(leftPanelImageRef.current);

      setLeftPanelTranslationX(translateX);
      prevTranslateX.current = translateX;

      setTimeout(() => {
        dispatch(onboardingActions.setOnboardingStep(OnboardingV2Step.SIGN_UP));
      }, 750);
    }
  }, [dispatch, leftPanelImageRef, translation]);

  useEffect(() => {
    const updateTranslation = () => {
      if (leftPanelImageRef.current) {
        const translateX = translation(leftPanelImageRef.current);

        setLeftPanelTranslationX(prevTranslateX.current + translateX);
        prevTranslateX.current = prevTranslateX.current + translateX;
      }
    };

    window.addEventListener('resize', updateTranslation);
    return () => window.removeEventListener('resize', updateTranslation);
  }, [leftPanelImageRef, translation]);

  useEffect(() => {
    if (!hasRun.current) {
      hasRun.current = true;

      if (!onboarding) {
        setTimeout(() => {
          if (leftPanelImageRef.current) {
            setLeftPanelTranslationX(translation(leftPanelImageRef.current));
          }
        }, 100);
      }
    }
  }, [leftPanelImageRef, onboarding, translation]);

  return { leftPanelTranslationX, animatedRightPanelInOnboarding };
};
