import { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import { images } from '../assets/images';
import { wait } from '../common/v2/hooks';
import { useAppSelector, useAppDispatch } from '../redux/store';
import { onboardingActions } from '../redux/onboarding';
import { OnboardingV2Step } from '../redux/onboardingTypes';
import { Box, Fade, Stack } from '@mui/material';
import TextFromMe from '../components_v2/chat/TextFromMe';
import TextFromSYD from '../components_v2/chat/TextFromSYD';
import { LoadingButton } from '@mui/lab';
import OnboardingView from './views/OnboardingView';
import SelectCountryForm from '../components_v2/forms/SelectCountryForm';
import AddressV2 from '../components_v2/forms/AddressvV2';
import { ContinueRef } from '../onboarding/types';
import StripeForm from '../components_v2/forms/StripeForm';
import SignUpForm from '../components_v2/forms/SignUpForm';
import { useSearchParams } from 'react-router-dom';
import CheckoutForm from '../components_v2/forms/CheckoutForm';
import { appEnvironment } from '../common/environment';
import { useTranslation } from 'react-i18next';

export default function OnboardingSignUpView() {
  const messages = useAppSelector((state) => state.onboarding.sydMessages);
  const name = useAppSelector((state) => state.checkout.personalData.name);
  const step = useAppSelector((state) => state.onboarding.onboardingStep);

  const stepRef = useRef(step);

  const dispatch = useAppDispatch();

  const hasRun = useRef(false);

  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (!hasRun.current) {
      hasRun.current = true;

      const messages = [
        t('hi'),
        t('imSyd'),
        t('anAIMentor'),
        t('firstLetsCreate'),
      ];

      const showMessages = async () => {
        for (const message of messages) {
          dispatch(
            onboardingActions.addMessage({
              from: 'syd',
              text: message,
              type: 'normal',
            })
          );

          await wait(appEnvironment.timeout);
        }
      };

      showMessages().then(() =>
        dispatch(
          onboardingActions.setOnboardingStep(OnboardingV2Step.MOBILE_BUTTON)
        )
      );
    }
  }, [dispatch, name, t]);

  const [imageLeft, setImageLeft] = useState(window.innerWidth / 2 - 310 / 2);
  const [imageTop, setImageTop] = useState(window.innerHeight / 2 - 267 / 2);
  const [scale, setScale] = useState(1);
  const [bottomViewHeight, setBottomViewHeight] = useState(0);
  const [buttonLogoContainerHeight, setButtonLogoContainerHeight] =
    useState(140);

  const [buttonOpacity, setButtonOpacity] = useState(0);
  const logoOpacity = useMemo(() => {
    return 1 - buttonOpacity;
  }, [buttonOpacity]);

  const buttonLogoContainerOpacity = useMemo(() => {
    if (buttonLogoContainerHeight === 140) {
      return 1;
    } else {
      return 0;
    }
  }, [buttonLogoContainerHeight]);

  useEffect(() => {
    if (stepRef.current !== step && step === OnboardingV2Step.MOBILE_BUTTON) {
      stepRef.current = step;

      setButtonOpacity(1);
    }
  }, [buttonOpacity, step]);

  useEffect(() => {
    if (stepRef.current !== step && step === OnboardingV2Step.DATE_OF_BIRTH) {
      stepRef.current = step;

      setBottomViewHeight(bottomHeight.current);
      setButtonLogoContainerHeight(0);
    }
  }, [step]);

  useEffect(() => {
    if (stepRef.current !== step && step === OnboardingV2Step.MOBILE_SIGN_UP) {
      stepRef.current = step;

      const rtl =
        document.getElementById('root')?.getAttribute('dir') === 'rtl';

      setImageLeft(rtl ? window.innerWidth - 195 : -105);
      setImageTop(-95);
      setScale(0.5);

      setButtonLogoContainerHeight(0);
    }
  }, [
    bottomViewHeight,
    buttonLogoContainerHeight,
    imageLeft,
    imageTop,
    scale,
    step,
  ]);

  const bottomHeight = useRef(0);

  const ref = useRef<ContinueRef>(null);
  const loading = useAppSelector((state) => state.checkout.loading);
  const buttonEnabled = useAppSelector(
    (state) => state.onboarding.buttonEnabled
  );

  const button = useCallback(
    (black = false) => {
      return (
        <LoadingButton
          variant="contained"
          endIcon={
            <Box
              component="img"
              src={black ? images.v2.button.tick : images.v2.button.tickBlack}
            />
          }
          className={`px-[24px] my-[20px] button bg-text`}
          sx={
            black
              ? {}
              : {
                  '&.MuiLoadingButton-root': {
                    background: 'white',
                    color: '#292D32',
                  },
                  '&.Mui-disabled': { background: '#FFFFFF99' },
                }
          }
          loading={loading}
          disabled={!buttonEnabled}
          onClick={() => {
            if (ref.current) {
              ref.current.continuePressed();
            }
          }}
        >
          {t('confirm')}
        </LoadingButton>
      );
    },
    [buttonEnabled, loading, t]
  );

  const fullView = useMemo(() => {
    switch (step) {
      case OnboardingV2Step.COUNTRY_SELECT:
        return <SelectCountryForm />;
      case OnboardingV2Step.MOBILE_SIGN_UP:
        return (
          <Stack alignSelf={'stretch'} flex={1}>
            <SignUpForm ref={ref} />

            {button(true)}
          </Stack>
        );
      case OnboardingV2Step.DELIVERY_ADDRESS:
        return (
          <Stack>
            <AddressV2 ref={ref} type="delivery" />

            {button(true)}
          </Stack>
        );
      case OnboardingV2Step.PAYMENT_INFO:
        return (
          <Stack alignSelf={'stretch'} className="mx-[16px]">
            <StripeForm ref={ref} />

            {button(true)}
          </Stack>
        );
    }
  }, [button, step]);

  if (searchParams.get('payment_intent') !== null) {
    return <CheckoutForm />;
  }

  return (
    <Stack
      className={`flex-1 self-stretch ${
        [
          OnboardingV2Step.MOBILE_SIGN_UP,
          OnboardingV2Step.PAYMENT_INFO,
          OnboardingV2Step.BILLING_ADDRESS,
          OnboardingV2Step.DELIVERY_ADDRESS,
        ].includes(step)
          ? 'bg-[#F5F6F6]'
          : 'bg-white'
      }`}
    >
      <Box
        component="img"
        src={images.v2.leftPanel.sydLeftPanelFace}
        className={`w-[310px] h-[267px] absolute z-10`}
        sx={{
          left: imageLeft,
          top: imageTop,
          transform: `scale(${scale})`,
          transition: 'all 0.5s ease',
        }}
      />

      <Box className="h-[60] self-stretch" />

      {[
        OnboardingV2Step.MOBILE_SIGN_UP,
        OnboardingV2Step.COUNTRY_SELECT,
        OnboardingV2Step.DELIVERY_ADDRESS,
        OnboardingV2Step.PAYMENT_INFO,
      ].includes(step) ? (
        <Fade in={true} timeout={1000}>
          <Stack
            flex={1}
            justifyContent={
              step === OnboardingV2Step.MOBILE_SIGN_UP ? 'flex-start' : 'center'
            }
            alignItems={'center'}
            sx={{ overflow: 'scroll' }}
          >
            {fullView}
          </Stack>
        </Fade>
      ) : (
        <>
          <Stack
            className="p-[24px] h-0 grow z-50"
            justifyContent="flex-end"
            alignItems={'flex-start'}
            gap="8px"
          >
            {messages.map((message) =>
              message.from === 'syd' ? (
                <TextFromSYD message={message} />
              ) : (
                <TextFromMe message={message} />
              )
            )}
          </Stack>
          <Stack
            className={`self-center justify-center items-center`}
            sx={{
              height: buttonLogoContainerHeight,
              opacity: buttonLogoContainerOpacity,
              transition: 'all 0.5s ease',
            }}
          >
            {step === OnboardingV2Step.MOBILE_ANIMATING ? (
              <Box className={`p-[24px]`} sx={{ opacity: logoOpacity }}>
                <Box
                  component={'img'}
                  src={images.v2.onboarding.securityLogos}
                />
              </Box>
            ) : (
              <Box sx={{ opacity: buttonOpacity }}>
                <LoadingButton
                  variant="contained"
                  className="px-[24px] bg-text"
                  onClick={() => {
                    dispatch(
                      onboardingActions.setOnboardingStep(
                        OnboardingV2Step.MOBILE_SIGN_UP
                      )
                    );
                  }}
                  endIcon={
                    <Box component="img" src={images.button.arrowRight} />
                  }
                >
                  {t('imIn')}
                </LoadingButton>
              </Box>
            )}
          </Stack>

          <Stack
            className="bg-text rounded-tl-[36px] rounded-tr-[36px] overflow-hidden"
            sx={{
              height: bottomViewHeight,
              position: 'relative',
              transition: 'all 0.5s ease',
            }}
          >
            <Box
              component={'img'}
              src={images.v2.onboarding.checkInBottomGradient}
              className={'absolute bottom-0 left-0'}
            />

            <OnboardingView
              onHeightMeasured={(value) => {
                bottomHeight.current = value;
              }}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
}
