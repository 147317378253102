import {
  useState,
  useCallback,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from 'react';
import PaginationView from '../../common/PaginationView';
import { answerQuestion } from '../../redux/onboarding';
import { useAppDispatch } from '../../redux/store';
import QuestionInputViewMobile, {
  SuggestionAnswer,
} from './QuestionInputViewMobile';
import QuestionInputItem from './QuestionInputItem';
import {
  analyticsEvents,
  analyticsScreenNames,
} from '../../common/analyticsEvents';
import {
  OnboardingContinueRef,
  OnboardingQuestion,
} from '../../redux/onboardingTypes';
import analyticsTracker from '../../common/analytics';
import { Stack } from '@mui/material';

const PAGE_SIZE = 4;

const GridFormMobile = forwardRef<OnboardingContinueRef, Props>(
  (props, ref) => {
    useImperativeHandle(ref, () => ({
      onContinuePressed: async () => {
        analyticsTracker.track(
          analyticsEvents.CLICK_CONFIRM_BUTTON,
          analyticsScreenPayload
        );

        dispatch(
          answerQuestion({
            answers: selection.map((item) => item.text),
            question_id: question.question_id,
          })
        );
      },
    }));

    const { question, onCustomAnswerEntered } = props;

    const [page, setPage] = useState(0);

    const [selection, setSelection] = useState<SuggestionAnswer[]>([]);

    const dispatch = useAppDispatch();

    const item = useCallback(
      (answer: SuggestionAnswer) => {
        return (
          <QuestionInputItem
            key={answer.id}
            text={answer.text}
            selected={
              selection.find((item) => item.text === answer.text) !== undefined
            }
            onSelected={() => {
              if (
                selection.find((item) => item.text === answer.text) !==
                undefined
              ) {
                setSelection((prev) =>
                  prev.filter((item) => item.text !== answer.text)
                );
              } else {
                setSelection((prev) => [...prev, answer]);
              }
            }}
          />
        );
      },
      [selection]
    );

    const analyticsScreenPayload = useMemo(() => {
      return {
        screen: analyticsScreenNames.QUESTION_SUGGESTION,
        question: question.question_text,
      };
    }, [question.question_text]);

    return (
      <Stack className="mt-[20px] mx-[16px]" flex={1} justifyContent={'center'}>
        <QuestionInputViewMobile
          selection={selection}
          onAdded={(answer) => setSelection((prev) => [...prev, answer])}
          onRemoved={(answer) =>
            setSelection((prev) => prev.filter((item) => item !== answer))
          }
          onCustomAnswerEntered={onCustomAnswerEntered}
        />

        <Stack
          gap={'8px'}
          className="flex-row justify-center items-start flex-wrap self-stretch mx-[20px] mt-[20px] flex-1"
        >
          {question.answers
            .slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)
            .map((answer) =>
              item({ id: answer.id, text: answer.text, valid: true })
            )}
        </Stack>

        {question.answers.length > PAGE_SIZE && (
          <PaginationView
            onLeftSelected={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
            onRightSelected={() => {
              if (page <= Math.floor(question.answers.length / PAGE_SIZE) - 1) {
                setPage(page + 1);
              }
            }}
            onPageSelected={setPage}
            page={page}
            totalPages={Math.floor(question.answers.length / PAGE_SIZE)}
            hideArrows={false}
            white={true}
          />
        )}
      </Stack>
    );
  }
);

interface Props {
  question: OnboardingQuestion;
  onCustomAnswerEntered: (text: string) => Promise<boolean>;
}

export default GridFormMobile;
