import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export const createStylesUtils = <T extends object>(t: {
  [key in keyof T]:
    | SystemStyleObject<Theme>
    | ((theme: Theme) => SystemStyleObject<Theme>);
}) => {
  return t;
};

export const FADE_TIMEOUT = 300;
