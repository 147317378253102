import { Box, Fade, Stack, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { images } from '../../../assets/images';
import analyticsTracker from '../../../common/analytics';
import { analyticsEvents } from '../../../common/analyticsEvents';
import { useSYDMessages } from '../../../common/v2/hooks';
import { useAppDispatch } from '../../../redux/store';
import { onboardingActions } from '../../../redux/onboarding';
import { useTranslation } from 'react-i18next';

export default function CompleteForm() {
  const { t } = useTranslation();
  const { showMessages } = useSYDMessages();

  const dispatch = useAppDispatch();
  const hasRun = useRef(false);
  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (hasRun.current) {
      return;
    }
    hasRun.current = true;

    const messages = [
      t('firstEvaluationIsCompletedThatsGreat'),
      t('yourUniquePlanIsReadyToAccessInTheApp'),
      t('scanTheCodeAndCheckItOut'),
    ];

    showMessages(messages);
  }, [showMessages, t]);

  useEffect(() => {
    analyticsTracker.track(analyticsEvents.VIEW_COMPLETED_SCREEN);

    setTimeout(() => {
      if (containerRef.current) {
        const { left, top, width, height } =
          containerRef.current.getBoundingClientRect();
        dispatch(
          onboardingActions.setQRPosition({
            x: left + width / 2,
            y: top + height / 2,
          })
        );
      }
    }, 500);
  }, [dispatch]);

  return (
    <Fade in={true}>
      <Stack className="items-end h-full justify-end flex-row relative flex-1">
        <Stack
          className="absolute w-full h-full"
          justifyContent={'center'}
          alignItems={'flex-end'}
          sx={{ paddingRight: '310px' }}
        >
          <Typography className="text-white font-semibold md:text-[36px] xl:text-[45px] 2xl:text-[64px] mb-[20px]">
            {t('WelcomeSimple')}
          </Typography>
        </Stack>

        <Box
          ref={containerRef}
          component={'img'}
          src={images.v2.onboarding.completeLogo}
        />
      </Stack>
    </Fade>
  );
}
