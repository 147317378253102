import { useCallback, useEffect } from 'react';
import { ASSETS_URL } from '../../common/environment';
import { answerQuestion } from '../../redux/onboarding';
import { useAppDispatch } from '../../redux/store';
import analyticsTracker from '../../common/analytics';
import { analyticsEvents } from '../../common/analyticsEvents';
import {
  OnboardingAnswer,
  OnboardingQuestion,
} from '../../redux/onboardingTypes';
import { Box, Stack, Typography } from '@mui/material';
import { createStylesUtils } from '../../common/common';

export default function LQIViewMobile(props: Props) {
  const { question } = props;

  const dispatch = useAppDispatch();

  const onAnswerSelected = useCallback(
    (answer: OnboardingAnswer) => {
      dispatch(
        answerQuestion({
          answers: [answer.id],
          question_id: question.question_id,
        })
      );
    },
    [dispatch, question.question_id]
  );

  useEffect(() => {
    analyticsTracker.track(analyticsEvents.VIEW_LQI_CARDS_SCREEN);
  }, []);

  return (
    <Stack flex={1} className="mx-[24px]">
      <Stack sx={styles.container} gap={'16px'}>
        <AnswerCell
          answer={question.answers[0]}
          onSelected={onAnswerSelected}
        />

        <Typography sx={styles.or}>or</Typography>

        <AnswerCell
          answer={question.answers[1]}
          onSelected={onAnswerSelected}
        />
      </Stack>

      <Stack className="flex-row items-center">
        <Typography sx={styles.description}>
          {question.answers[0].description}
        </Typography>

        <Typography sx={styles.or} className="opacity-0">
          or
        </Typography>

        <Typography sx={styles.description}>
          {question.answers[1].description}
        </Typography>
      </Stack>
    </Stack>
  );
}

const AnswerCell = ({
  answer,
  onSelected,
}: {
  answer: OnboardingAnswer;
  onSelected: (answer: OnboardingAnswer) => void;
}) => {
  return (
    <Stack sx={{ width: 0, flexGrow: 1 }} onClick={() => onSelected(answer)}>
      <Stack className="relative">
        <Stack
          className="relative overflow-hidden z-10"
          sx={[styles.lqiCell, styles.shadow]}
        >
          <Stack sx={styles.backgroundImageContainer}>
            <Box
              component={'img'}
              src={`${ASSETS_URL}/uploads/lqi_v4/background_images/Light/${answer.imgUrl_bkg}`}
              sx={styles.backgroundImage}
            />
          </Stack>

          <Stack sx={styles.topContainer}>
            <Box
              component={'img'}
              src={`${ASSETS_URL}/uploads/lqi_v4/icons/Black/${answer.imgUrl}`}
              sx={styles.image}
            />

            <Typography sx={styles.title}>{answer.text}</Typography>
          </Stack>
        </Stack>

        <Stack
          sx={[
            styles.lqiCellPlaceholder,
            styles.shadow,
            { transform: 'rotate(-10deg)' },
          ]}
        />
        <Stack
          sx={[
            styles.lqiCellPlaceholder,
            styles.shadow,
            { transform: 'rotate(10deg)' },
          ]}
        />
        <Stack
          sx={[
            styles.lqiCellPlaceholder,
            styles.shadow,
            { transform: 'rotate(-10deg)' },
          ]}
        />
      </Stack>
    </Stack>
  );
};

interface Props {
  question: OnboardingQuestion;
}

const styles = createStylesUtils({
  container: {
    marginTop: '40px',
    alignItems: 'center',
    paddingBottom: '40px',
    flexDirection: 'row',
  },

  lqiCell: {
    alignSelf: 'stretch',
    flexGrow: 1,
    height: '150px',
    borderRadius: '28px',
    backgroundColor: '#F5F6F6',
    paddingTop: '20px',
    paddingBottom: '16px',
    paddingHorizontal: '28px',

    justifyContent: 'space-between',
    overflow: 'visible',
  },

  shadow: {
    shadowColor: '#0000011',
    shadowOffset: {
      width: 0,
      height: '1px',
    },
    shadowOpacity: 0.19,
    shadowRadius: 4.65,
    elevation: 4,
  },

  lqiCellPlaceholder: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '150px',
    borderRadius: '28px',
    backgroundColor: '#F5F6F6',
  },

  backgroundImageContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },

  backgroundImage: {
    width: '130px',
    height: '130px',
  },

  topContainer: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    gap: '10px',
    marginBottom: '-5px',

    zIndex: 1,
  },

  title: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#292D32',
  },

  description: {
    fontWeight: 500,
    color: '#FFFFFF66',
    textAlign: 'center',
    fontSize: '12px',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    width: 0,
    flexGrow: 1,
  },

  image: {
    width: '28px',
    height: '28px',
  },

  or: {
    fontWeight: 600,
    color: 'white',
    fontSize: '12px',
    marginHorizontal: '10px',
  },
});
