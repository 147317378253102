import { useEffect, useMemo, useRef } from 'react';
import { images } from '../../assets/images';
import { useAppSelector } from '../../redux/store';
import { OnboardingV2Step } from '../../redux/onboardingTypes';
import { Box, Fade, Stack } from '@mui/material';
import DateOfBirth from '../../components_v2/forms/DateOfBirth';
import { LoadingButton } from '@mui/lab';
import { ContinueRef } from '../../onboarding/types';
import CountryForm from '../../components_v2/forms/CountryForm';
import GenderForm from '../../components_v2/forms/GenderForm';
import SummaryV2Form from '../../components_v2/forms/SummaryV2Form';
import { useTranslation } from 'react-i18next';

export default function OnboardingView(props: Props) {
  const { t } = useTranslation();
  const { onHeightMeasured } = props;

  const step = useAppSelector((state) => state.onboarding.onboardingStep);
  const loading = useAppSelector((state) => state.checkout.loading);
  const buttonEnabled = useAppSelector(
    (state) => state.onboarding.buttonEnabled
  );
  const showingMessages = useAppSelector(
    (state) => state.onboarding.showingMessages
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const ref = useRef<ContinueRef>(null);

  useEffect(() => {
    if (containerRef.current) {
      onHeightMeasured(containerRef.current.getBoundingClientRect().height);
    }
  }, [onHeightMeasured]);

  const view = useMemo(() => {
    switch (step) {
      case OnboardingV2Step.DATE_OF_BIRTH:
        return <DateOfBirth ref={ref} />;
      case OnboardingV2Step.COUNTRY:
        return <CountryForm ref={ref} />;
      case OnboardingV2Step.GENDER:
        return <GenderForm ref={ref} />;
      case OnboardingV2Step.SUMMARY:
        return <SummaryV2Form ref={ref} />;
    }
  }, [step]);

  return (
    <Stack
      ref={containerRef}
      className={
        'self-stretch bg-transparent min-h-[360px] rounded-[36px] rounded-br-[0px] rounded-bl-[0px] mt-[20] justify-center absolute top-0 left-0 right-0 bottom-0'
      }
    >
      <Box
        component={'img'}
        src={images.v2.onboarding.checkInBottomGradient}
        className={'absolute bottom-0 left-0 z-[-1]'}
      />

      <Fade in={step !== OnboardingV2Step.MOBILE_NONE} className={'flex-1'}>
        <Stack>{view}</Stack>
      </Fade>

      {step !== OnboardingV2Step.MOBILE_NONE && !showingMessages && (
        <LoadingButton
          variant="contained"
          endIcon={<Box component="img" src={images.v2.button.tickBlack} />}
          className={`button mobile mb-10 mt-[0px]`}
          sx={{
            '&.MuiLoadingButton-root': { background: 'white' },
            '&.Mui-disabled': { background: '#FFFFFF99' },
          }}
          loading={loading}
          disabled={!buttonEnabled}
          onClick={() => {
            if (ref.current) {
              ref.current.continuePressed();
            }
          }}
        >
          {t('confirm')}
        </LoadingButton>
      )}
    </Stack>
  );
}

interface Props {
  onHeightMeasured: (height: number) => void;
}
