import { useRef, useEffect, useState, useMemo } from 'react';
import { images } from '../assets/images';
import { useSYDMessages } from '../common/v2/hooks';
import { useAppSelector, useAppDispatch } from '../redux/store';
import { nextQuestion } from '../redux/onboarding';
import {
  Box,
  GlobalStyles,
  Stack,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import TextFromMe from '../components_v2/chat/TextFromMe';
import TextFromSYD from '../components_v2/chat/TextFromSYD';
import OnboardingQuestionsView from './views/OnboardingQuestionsView';
import { theme } from '../common/theme';
import { useGetUserProfileQuery } from '../api/api';
import { useTranslation } from 'react-i18next';
import HeaderProgressBarMobile from '../components_v2/header/HeaderPogressMobile';

export default function OnboardingSignUpView() {
  const { t } = useTranslation();

  const messages = useAppSelector((state) => state.onboarding.sydMessages);
  const questions = useAppSelector((state) => state.onboarding.questions);

  const dispatch = useAppDispatch();

  const hasRun = useRef(false);
  const [ready, setReady] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);

  const { data } = useGetUserProfileQuery();

  const { showMessages } = useSYDMessages();

  useEffect(() => {
    if (!hasRun.current && data) {
      hasRun.current = true;

      const messages = [
        t('hi'),
        t('imSyd'),
        t('anAIMentor'),
        t('letsGetToKnowEachOther'),
      ];

      showMessages(messages).then(() => setReady(true));
    }
  }, [data, dispatch, showMessages, t]);

  const [imageLeft, setImageLeft] = useState(window.innerWidth / 2 - 310 / 2);
  const [imageTop, setImageTop] = useState(window.innerHeight / 2 - 267 / 2);
  const [scale, setScale] = useState(1);
  const [bottomViewHeight, setBottomViewHeight] = useState(0);

  useEffect(() => {
    if (ready) {
      const rtl =
        document.getElementById('root')?.getAttribute('dir') === 'rtl';

      setImageLeft(rtl ? window.innerWidth - 195 : -105);
      setImageTop(-95);
      setScale(0.5);

      setBottomViewHeight(bottomHeight.current);

      setTimeout(() => {
        setHasAnimated(true);
        dispatch(nextQuestion());
      }, 1000);
    }
  }, [dispatch, ready]);

  const bottomHeight = useRef(0);

  const lastQuestion = useMemo(() => {
    if (questions.length === 0) {
      return undefined;
    }

    return questions[questions.length - 1];
  }, [questions]);

  const lqiHeader = useMemo(() => {
    if (!lastQuestion || !lastQuestion.status) {
      return null;
    }

    return (
      <Stack direction="row" alignItems="center" gap="8px" className="m-[10px]">
        <video
          src={images.v2.onboarding.lqiHeaderVideoMobile}
          autoPlay={true}
          loop={true}
          controls={false}
          muted={true}
          className="h-[60px]"
          playsInline={true}
        />
      </Stack>
    );
  }, [lastQuestion]);

  useEffect(() => {
    if (lastQuestion && lastQuestion.finished) {
      setBottomViewHeight(window.innerHeight - 200);
    }
  }, [lastQuestion]);

  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{ body: { background: '#F5F6F6', padding: 0, margin: 0 } }}
        />

        <Stack className="root-container">
          {lastQuestion && !lastQuestion.finished && (
            <HeaderProgressBarMobile
              label={t('details')}
              step={lastQuestion.question_idx}
              totalSteps={lastQuestion.questions_total}
              white={true}
              rightMargin={true}
            />
          )}

          <Stack className={`flex-1 self-stretch bg-[#F6F5F5]`}>
            <Box
              component="img"
              src={images.v2.leftPanel.sydLeftPanelFace}
              className={`w-[310px] h-[267px] absolute z-10`}
              sx={{
                left: imageLeft,
                top: imageTop,
                transform: `scale(${scale})`,
                transition: 'all 0.5s ease',
              }}
            />

            <Stack
              flexDirection={'row'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              className="self-stretch mt-[10px]"
            >
              {lqiHeader}
            </Stack>

            <Stack
              className="p-[24px] h-0 grow z-50"
              justifyContent="flex-end"
              alignItems={'flex-start'}
              gap="8px"
            >
              {messages.map((message) =>
                message.from === 'syd' ? (
                  <TextFromSYD message={message} />
                ) : (
                  <TextFromMe message={message} />
                )
              )}
            </Stack>

            <Stack
              className="bg-text rounded-tl-[36px] rounded-tr-[36px] overflow-hidden max-h-[400px]"
              sx={{
                height: bottomViewHeight,
                transition: 'all 0.5s ease',
                position: 'relative',
              }}
            >
              <Box
                component={'img'}
                src={images.v2.onboarding.checkInBottomGradient}
                className={'absolute bottom-0 left-0'}
              />

              <OnboardingQuestionsView
                onHeightMeasured={(value) => {
                  bottomHeight.current = value;

                  if (
                    Math.abs(bottomViewHeight - value) > 10 &&
                    hasAnimated &&
                    !lastQuestion?.finished
                  ) {
                    setBottomViewHeight(value);
                  }
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
