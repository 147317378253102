import { useMemo } from 'react';
import { useMobile, useSmallDevice } from '../../common/hooks';

export const useTextHookUI = () => {
  const mobile = useMobile();
  const smallDevice = useSmallDevice();

  const messagesVisible = useMemo(() => {
    if (!mobile) {
      return 5;
    } else if (smallDevice) {
      return 2;
    } else {
      return 3;
    }
  }, [mobile, smallDevice]);

  return { messagesVisible };
};
