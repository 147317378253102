import { Box, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import validator from '../../onboarding/common/validator';
import { useMobile } from '../../common/hooks';
import { useTranslation } from 'react-i18next';
import { images } from '../../assets/images';

export default function PasswordStrengthView(props: Props) {
  const { t } = useTranslation();
  const { password } = props;

  const [passwordStrength, setPasswordStrength] = useState(4);

  const [passwordLength, setPasswordLength] = useState(false);
  const [
    passwordHasUppercaseAndLowercase,
    setPasswordHasUppercaseAndLowercase,
  ] = useState(false);
  const [passwordHasDigit, setPasswordHasDigit] = useState(false);
  const [passwordHasSpecialCharacters, setPasswordHasSpecialCharacters] =
    useState(false);

  const mobile = useMobile();

  useEffect(() => {
    let strength = 0;

    if (password.length > 0) {
      strength++;
    }

    if (validator.passwordLength(password)) {
      strength++;
      setPasswordLength(true);
    } else {
      setPasswordLength(false);
    }

    if (validator.passwordHasUppercaseAndLowercase(password)) {
      strength++;
      setPasswordHasUppercaseAndLowercase(true);
    } else {
      setPasswordHasUppercaseAndLowercase(false);
    }

    if (validator.passwordHasDigit(password)) {
      setPasswordHasDigit(true);
      strength++;
    } else {
      setPasswordHasDigit(false);
    }

    if (validator.passwordHasSpecialCharacters(password)) {
      strength++;
      setPasswordHasSpecialCharacters(true);
    } else {
      setPasswordHasSpecialCharacters(false);
    }

    setPasswordStrength(strength);
  }, [password]);

  const backgroundColor = useCallback(
    (index: number) => {
      if (index < passwordStrength) {
        switch (passwordStrength) {
          case 1:
            return 'bg-[#E84D8A]';
          case 2:
            return 'bg-[#F7C824]';
          case 3:
            return 'bg-[#94C261]';
          case 4:
            return 'bg-[#00C386]';
          case 5:
            return 'bg-[#0ABAB5]';
        }
      }

      return 'bg-white';
    },
    [passwordStrength]
  );

  const text = useCallback(
    (text: string, valid: boolean, end: boolean) => {
      return (
        <Stack
          direction="row"
          alignItems="center"
          className={`items-center gap-x-[10px] ${
            end && !mobile ? 'flex-row-reverse' : 'flex-row'
          }`}
        >
          {valid && (
            <Box component="img" src={images.v2.password.passwordTick} />
          )}
          <Typography
            className={`text-primary20 text-[10px] font-mediu ${
              valid ? 'text-mint' : ''
            }`}
          >
            {text}
          </Typography>
        </Stack>
      );
    },
    [mobile]
  );

  return (
    <Stack gap="12px" className="mx-4 mt-4">
      <Stack gap={mobile ? '4px' : '10px'} direction={'row'}>
        <Box className={`h-1.5 rounded-md flex-1 ${backgroundColor(0)}`} />
        <Box className={`h-1.5 rounded-md flex-1 ${backgroundColor(1)}`} />
        <Box className={`h-1.5 rounded-md flex-1 ${backgroundColor(2)}`} />
        <Box className={`h-1.5 rounded-md flex-1 ${backgroundColor(3)}`} />
        <Box className={`h-1.5 rounded-md flex-1 ${backgroundColor(4)}`} />
      </Stack>

      <Stack className="gap-y-[10px]">
        {mobile ? (
          <>
            {text(t('atLeast8Characters'), passwordLength, false)}
            {text(t('mixtureOfLettersAndNumbers'), passwordHasDigit, false)}
            {text(
              t('bothUppercaseAndLowercase'),
              passwordHasUppercaseAndLowercase,
              false
            )}
            {text(
              t('atLeastOneSpecialCharacter'),
              passwordHasSpecialCharacters,
              false
            )}
          </>
        ) : (
          <>
            <Stack className="flex-row justify-between items-center">
              {text(t('atLeast8Characters'), passwordLength, false)}
              {text(t('mixtureOfLettersAndNumbers'), passwordHasDigit, true)}
            </Stack>

            <Stack className="flex-row justify-between items-center">
              {text(
                t('bothUppercaseAndLowercase'),
                passwordHasUppercaseAndLowercase,
                false
              )}
              {text(
                t('atLeastOneSpecialCharacter'),
                passwordHasSpecialCharacters,
                true
              )}
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
}

interface Props {
  password: string;
}
