import { Box, Stack, Typography } from '@mui/material';
import { createStylesUtils } from '../../../common/common';
import {
  OnboardingAnswer,
  OnboardingQuestion,
} from '../../../redux/onboardingTypes';
import { useAppDispatch } from '../../../redux/store';
import { answerQuestion } from '../../../redux/onboarding';
import { useDrag } from '@use-gesture/react';
import { useMobile } from '../../../common/hooks';
import { useEffect } from 'react';
import analyticsTracker from '../../../common/analytics';
import { analyticsEvents } from '../../../common/analyticsEvents';
import { ASSETS_URL } from '../../../common/environment';
import { useQuestionNavigation } from '../../common/hooks';

const LQIForm = (props: Props) => {
  const { question } = props;

  const mobile = useMobile();

  useEffect(() => {
    analyticsTracker.track(analyticsEvents.VIEW_LQI_CARDS_SCREEN);
  }, []);

  return (
    <Stack direction="column" alignItems="center">
      <Stack
        direction={mobile ? 'column' : 'row'}
        alignItems="center"
        spacing={mobile ? undefined : '50px'}
        justifyContent="center"
        sx={mobile ? { margin: '20px', padding: '20px 0px 20px' } : {}}
      >
        <Box
          sx={[
            {
              width: '250px',
              height: '300px',
              position: 'relative',
            },
            mobile ? styles.sizeMobile : {},
          ]}
        >
          <Card
            index={0}
            answer={question.answers[0]}
            questionId={question.question_id}
          />
          <Card index={1} />
          <Card index={2} />
          <Card index={3} />
        </Box>

        <Typography sx={[styles.text, mobile ? { marginBottom: '30px' } : {}]}>
          or
        </Typography>

        <Box
          sx={[
            {
              width: '250px',
              height: '300px',
              position: 'relative',
            },
            mobile ? styles.sizeMobile : {},
          ]}
        >
          <Card
            index={0}
            answer={question.answers[1]}
            questionId={question.question_id}
          />
          <Card index={1} />
          <Card index={2} />
          <Card index={3} />
        </Box>
      </Stack>

      {!mobile && (
        <Stack
          direction="row"
          sx={{ alignSelf: 'stretch', marginTop: '20px' }}
          justifyContent="space-between"
        >
          <Typography sx={styles.note}>
            {question.answers[0].description}
          </Typography>
          <Typography sx={styles.note}>
            {question.answers[1].description}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default LQIForm;

const Card = ({
  index,
  answer,
  questionId,
}: {
  index: number;
  answer?: OnboardingAnswer;
  questionId?: string;
}) => {
  const dispatch = useAppDispatch();

  const mobile = useMobile();

  const { goNext } = useQuestionNavigation();

  const drag = useDrag(({ tap }) => {
    if (tap) {
      dispatch(
        answerQuestion({
          answers: [answer!.id],
          question_id: questionId!,
        })
      ).then(goNext);
    }
  });

  if (mobile) {
    return (
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={[
          styles.container,
          styles.sizeMobile,
          styles.containerMobile,
          {
            zIndex: -1 * index,
            transform: `rotate(${index * 6 * (index % 2 === 1 ? -1 : 1)}deg)`,
            ':hover': {
              cursor: index === 0 ? 'pointer' : undefined,
            },
          },
        ]}
        {...(index === 0 ? drag() : {})}
      >
        {answer && (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ alignSelf: 'stretch' }}
            >
              <Box
                component="img"
                sx={[styles.icon, { marginTop: '0px' }]}
                src={`${ASSETS_URL}/uploads/lqi_v4/icons/Black/${answer.imgUrl}`}
              />
              <Typography sx={styles.textMobile}>{answer.text}</Typography>
            </Stack>

            <Box sx={styles.lqiImabeMobileContainer}>
              <Box
                component="img"
                sx={styles.lqiImageMobile}
                src={`${ASSETS_URL}/uploads/lqi_v4/background_images/Light/${answer.imgUrl_bkg}`}
              />
            </Box>

            <Typography sx={styles.note}>{answer.description}</Typography>
          </>
        )}
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={[
        styles.container,
        mobile ? styles.sizeMobile : {},
        mobile ? styles.containerMobile : {},
        {
          zIndex: -1 * index,
          transform: `rotate(${index * 6 * (index % 2 === 1 ? -1 : 1)}deg)`,

          ':hover': {
            cursor: index === 0 ? 'pointer' : undefined,
          },
        },
      ]}
      {...(index === 0 ? drag() : {})}
    >
      {answer && (
        <>
          <Box
            component="img"
            sx={styles.lqiImage}
            src={`${ASSETS_URL}/uploads/lqi_v4/background_images/Light/${answer.imgUrl_bkg}`}
          />
          <Box
            component="img"
            sx={styles.icon}
            src={`${ASSETS_URL}/uploads/lqi_v4/icons/Black/${answer.imgUrl}`}
          />
          <Typography sx={styles.text}>{answer.text}</Typography>
        </>
      )}
    </Stack>
  );
};

const styles = createStylesUtils({
  sizeMobile: {
    width: '260px',
    height: '220px',
  },

  containerMobile: {
    padding: '24px',
  },

  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '250px',
    height: '300px',
    borderRadius: '36px',
    padding: '40px',
    boxSizing: 'border-box',
    background: '#F5F6F6',
    boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.1)',
    touchAction: 'none',

    '& img': {
      pointerEvents: 'none',
      WebkitTapHighlightColor: 'transparent',
      WebkitTouchCallout: 'none',
      WebkitUserSelect: 'none',
      KhtmlUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',

      outlineWidth: '0px',
      ':focus': {
        outline: 'none',
      },
    },
  },

  lqiImage: { width: '115px', height: '115px', objectFit: 'cover' },

  lqiImabeMobileContainer: {
    position: 'absolute',
    top: '30px',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: -1,
  },

  lqiImageMobile: {
    width: '115px',
    height: '115px',
    objectFit: 'cover',
  },

  icon: {
    width: '24px',
    height: '24px',
    objectFit: 'cover',
    marginTop: '20px',
  },

  text: {
    marginTop: '20px',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#292D32',
    textAlign: 'center',
  },

  textMobile: {
    fontSize: '12px',
    lineHeight: '12px',
    color: '#292D32',
    fontWeight: '600',
  },

  note: {
    marginTop: '40px',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#292D3266',
    textAlign: 'center',
    maxWidth: '250px',

    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
  },

  noteMobile: {
    marginTop: '30px',
    maxWidth: '200px',
  },
});

interface Props {
  question: OnboardingQuestion;
}
