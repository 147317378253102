import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { images } from '../../assets/images';

export default function GridFormItem(props: Props) {
  const { text, onSelected, selected } = props;

  const textClass = useMemo(() => {
    if (!selected) {
      return 'text-text';
    } else {
      return 'text-[#0ABAB5]';
    }
  }, [selected]);

  const borderColorClass = useMemo(() => {
    if (selected) {
      return 'border-[#0ABAB5]';
    } else {
      return 'border-text';
    }
  }, [selected]);

  return (
    <Stack
      className={`p-4 rounded-[36px] border-[2px] hover:cursor-pointer ${borderColorClass}`}
      onClick={onSelected}
    >
      <Stack
        direction="row"
        justifyContent="center"
        gap="10px"
        alignItems="center"
      >
        {selected && (
          <Box component="img" src={images.v2.onboarding.gridItemCheck} />
        )}

        <Typography className={`text-[14px] font-medium ${textClass}`}>
          {text}
        </Typography>
      </Stack>
    </Stack>
  );
}

interface Props {
  text: string;
  onSelected: () => void;

  selected: boolean;
}
